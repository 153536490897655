// utils/orderUtils.js
export function generateOrderNumber() {
   // 获取当前日期和时间
   const now = new Date();
 
   // 格式化日期和时间
   const year = now.getFullYear();
   const month = String(now.getMonth() + 1).padStart(2, '0'); // 加1是因为getMonth()返回0-11
   const day = String(now.getDate()).padStart(2, '0');
   const hours = String(now.getHours()).padStart(2, '0');
   const minutes = String(now.getMinutes()).padStart(2, '0');
   const seconds = String(now.getSeconds()).padStart(2, '0');
 
   // 生成随机数部分（这里用6位数字）
   const randomPart = Math.random().toString().substr(2, 6);
 
   // 组合订单编号
   return `${year}${month}${day}${hours}${minutes}${seconds}${randomPart}`;
 }