import http from '@/utils/http'

// 支付宝支付
export const payApi = (data) => http ({
   url: '/alipay/pay',
   method: 'get',
   params: data
})

// 用户下单
export const saveOrdersApi = (data) => http ({
   url: '/order/save-orders',
   method: 'post',
   data: data
})

// 获取订单详情
export const getOrderInfoApi = (data) => http ({
   url: '/order/info',
   method: 'get',
   params: data
})

// 获取订单详情
export const receiveGoodsApi = (data) => http ({
   url: '/order/receive-goods',
   method: 'get',
   params: data
})