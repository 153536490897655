import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// 使用插件
Vue.use(VueRouter)

/* 解决Redirected when going from "/login" to "/" via a navigation guard.报错信息 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
   return originalPush.call(this, location).catch(err => err)
}

// 引入路由组件
import Login from '@/views/login/login'
import Index from '@/views/index/index'
import Search from '@/views/search/Search'
import Product from '@/views/product/Product'
import Cart from '@/views/cart/Cart'
import Pay from '@/views/pay/Pay'
import Home from '@/views/home/Home'
import Order from '@/views/order/Order'
import Register from '@/views/register/Register'
import NotFound from '@/views/not-found/404'

// 配置路由
const routes = [
   {
      path: "/",
      name: "index",
      component: Index,
   },
   {
      path: "/register",
      name: "register",
      component: Register,
   },
   {
      path: "/search",
      name: "search",
      component: Search,
      props: true
   },
   {
      path: "/product",
      name: "product",
      component: Product,
   },
   {
      path: "/cart",
      name: "cart",
      component: Cart,
   },
   {
      path: "/pay",
      name: "pay",
      component: Pay,
      props: route => ({ selectedItems: route.params.selectedItems })  // 确保props正确传递
   },
   {
      path: "/order",
      name: "order",
      component: Order,
   },
   {
      path: "/login",
      name: "login",
      component: Login
   },
   {
      path: "/home",
      name: "home",
      component: Home
   },
   {
      path: "*",
      name: "not-found",
      component: NotFound
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   // 配置滚动行为
   scrollBehavior(to, from, savedPosition) {
      // 如果 `savedPosition` 存在，浏览器会尝试像浏览器的原生行为那样进行滚动
      if (savedPosition) {
         return savedPosition;
      } else {
         // 返回的对象可以是一个数字或者是一个对象
         return { x: 0, y: 0 };
      }
   }
})

export default router