<template>
  <div class="index">
    <!-- 头部 -->
    <Header></Header>
    <!-- 轮播图 -->
    <div class="banner">
      <div class="banner-content">
        <el-carousel indicator-position="outside" :interval="4000" height="482px" arrow="always">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.imgUrl" class="banner-img" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 分类 -->
    <div class="type">
      <div class="type-content">
        <div class="out-top" v-for="(item, index) in typeList" :key="index" @click="goToSearch(item.categoryId)">
          <div class="features-thumb">
            <img :src="item.url" alt="">
          </div>
          <div class="features-content">
            <h4><a class="item-a" target="_blank">{{ item.title }}</a></h4>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 限时秒杀 -->
    <div class="miao-sha">
      <div id="MiaoSha_section"><!-- 秒杀商品的div -->
        <div id="MiaoSha_one">
          <p>限时秒杀</p>
          <p>FLASH DEALS</p>
          <p><img src="../../assets/img/shandian.png"></p>
          <p>本场距离结束还剩</p>
          <div class="aui-sale-count">
            <div class="aui-sale-count-item">
              <span id="hour_show">00时</span>
            </div>
            <div class="aui-sale-count-item">
              <span id="minute_show">00分</span>
            </div>
            <div class="aui-sale-count-item">
              <span id="second_show">00秒</span>
            </div>
          </div>
        </div>
        <div id="MiaoSha_two">
          <div id="kuan" v-for="(item, index) in msList" :key="index">
            <dl>
              <dt><img :src="item.img" alt="哎呀，图片不小心没了。刷新下试试吧！" width="140px"></dt>
              <dd>{{ item.name }}</dd>
              <dd><span>{{ item.newPrice }}</span> 原价：<span>{{ item.oldPrice }}</span></dd>
            </dl>
          </div>
        </div>
        <div id="MiaoSha_Three">
          <img src="../../assets/img/ms/s_1.jpg" width="170px" height="255px" style="margin: 10px 0px 0px 10px"
            class="RightImage" />
        </div>
      </div>
    </div>
    <!-- 新品上架 -->
    <div class="new-content">
      <div class="wenzi" id="wenzi1" style="margin-top: 50px!important;">
        <p>新品上架<span>NEW PRODUCTS</span></p>
      </div>
      <div class="zhuti_one new-product">
        <div v-for="(shop, idx) in newProductList.slice(0, 5)" :key="idx" 
          @click=goToProductDetail(shop.id)
          class="shangpins"
          style="width: 227px;height: 300px;cursor: pointer; margin-left: 0!important;" 
        >
          <dl class="tus">
            <dt><img :src="shop.coverUrl" width="180" height="180px"></dt>
            <dd style="overflow: hidden;height: 40px; font-size: 13px; line-height: 17px;">{{ shop.name }}</dd>
            <dd></dd>
            <dd>￥{{ shop.price }}</dd>
            <em></em>
          </dl>
        </div>
      </div>
    </div>
    
    <!-- 3个大分类 -->
    <div class="classify" style="position: relative;">
      <div class="new-conten" style="position: absolute; top: 21px !important; width: 100%;">
        <el-divider> ○○ 农产品分类 ○○</el-divider>
      </div>
      
      <div class="classify-content" v-for="(item, index) in productList" :key="index">
        <div class="wenzi" id="wenzi1" style="margin-top: 100px; !important">
          <p>{{ classifyList[index].title }}<span>FRESH & NUTRITION</span></p>
        </div>
        <div class="zhuti" id="zhuti1">
          <img :src="classifyList[index].leftImg" width="230px" height="614px" class="ban">
          <div class="zhuti_one">
            <div class="baokuan">
              <ul>
                <li class="hwtj" :style="{color: sty[index].color, backgroundColor: sty[index].bgc }">好物推荐</li>
                <li class="jrbk1">今日爆款</li>
              </ul>
              <img :src="classifyList[index].rightImg" style="margin-left: 10px;margin-top: 10px" width="448px">
            </div>
            <div v-for="(shop, idx) in item.productList.slice(0, 2)" :key="idx"
              @click=goToProductDetail(shop.id)
              style="width: 227px;cursor:pointer;height: 300px;overflow: hidden; float: left;margin-left: 12px;cursor: pointer;">
              <dl class="tus">
                <dt><img :src="shop.coverUrl" width="180" height="180px"></dt>
                <dd style="overflow: hidden;height: 40px; font-size: 13px; line-height: 17px;">{{ shop.name }}</dd>
                <dd></dd>
                <dd>￥{{ shop.price }}</dd>
              </dl>
            </div>
          </div>
          <div class="zhuti_one">
            <div v-for="(shop, idx) in item.productList.slice(2, 6)" :key="idx" 
                @click=goToProductDetail(shop.id)
                style="width: 227px;height: 300px;overflow: hidden; float: left;cursor: pointer;" class="shangpins">
              <dl class="tus">
                <dt><img :src="shop.coverUrl" width="180" height="180px"></dt>
                <dd style="overflow: hidden;height: 40px; font-size: 13px; line-height: 17px;">{{ shop.name }}</dd>
                <dd></dd>
                <dd>￥{{ shop.price }}</dd>
                <em></em>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 35px; background-color: transparent;"></div>
    <template>
      <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
    </template>
    <Footer></Footer>
  </div>
</template>
 
<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { getBannerListApi, getproductListApi } from "@/api/index.js"
import { getNewProductListApi } from "@/api/product.js"
export default {
  name: "",
  data() {
    return {
      bannerList: [],
      navList: [],
      newProductList: [],
      productList: [],
      typeList: [
        {
          url: require("../../assets/img/type/features_img01.png"),
          title: "水果",
          categoryId: 7,
          content: "新鲜果实 口感鲜美"
        },
        {
          url: require("../../assets/img/type/features_img02.png"),
          title: "蔬菜",
          categoryId: 1,
          content: "嫩绿多汁 营养丰富"
        },
        {
          url: require("../../assets/img/type/features_img04.png"),
          title: "水产",
          categoryId: 6,
          content: "鲜味十足 营养丰富"
        },
        {
          url: require("../../assets/img/type/features_img03.png"),
          title: "禽畜",
          categoryId: 3,
          content: "产地直发 海量供应"
        },
      ],
      msList: [
        {
          img: require("../../assets/img/ms/1.jpg"),
          name: "大棚甜王西瓜场地优新...",
          newPrice: "18.99",
          oldPrice: "29.00"
        },
        {
          img: require("../../assets/img/ms/2.jpg"),
          name: "软心红心番石榴新鲜水...",
          newPrice: "8.99",
          oldPrice: "18.90"
        },
        {
          img: require("../../assets/img/ms/3.jpg"),
          name: "黄柠檬新鲜水果皮薄当...",
          newPrice: "13.59",
          oldPrice: "18.50"
        },
        {
          img: require("../../assets/img/ms/4.jpg"),
          name: "黄心大白菜大量上市货...",
          newPrice: "2.59",
          oldPrice: "8.50"
        }
      ],
      classifyList: [
        {leftImg: require("@/assets/img/classify/sg1.jpg"), rightImg: require("@/assets/img/classify/banner1.jpg"), title: "新鲜水果"},
        {leftImg: require("@/assets/img/classify/sg2.jpg"), rightImg: require("@/assets/img/classify/banner2.jpg"), title: "新鲜蔬菜"},
        {leftImg: require("@/assets/img/classify/sg3.jpg"), rightImg: require("@/assets/img/classify/banner3.jpg"), title: "新鲜水产"},
      ],
      sty: [
        {color: "#dd5353", bgc: "#ffcbcb"},
        {color: "#3ea82c", bgc: "#ccfecb"},
        {color: "#3288ca", bgc: "#c5e3ff"},
      ]
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.__getBannerList();
    this.__getProductList();
    this.__getNewProductList();
  },
  methods: {
    async __getBannerList() {
      const res = await getBannerListApi();
      if (!res) return;
      this.bannerList = res.data;
    },
    async __getProductList() {
      const res = await getproductListApi();
      // console.log(res);
      if (!res) return;
      this.productList = res.data;
    },
    async __getNewProductList() {
      const res = await getNewProductListApi();
      if (!res) return;
      this.newProductList = res.data;
    },
    goToSearch(categoryId) {
        this.$router.push({ path: '/search', query: {categoryId: categoryId} });
    },
    goToProductDetail(productId) {
      this.$router.push({ path: '/product', query: {productId: productId} });
    }
  }
}
</script>
 
<style lang="less" scoped>
/deep/ .el-divider__text {
  background-color: transparent;
  color: #dd5353 !important;
  font-weight: 700;
}

.new-content {
  width: 1190px;
  margin: 0 auto;
}


// 3个大类开始
.classify {
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;

  .classify-content {
    width: 1190px;
    height: 614px;
    margin: 0 auto;

  }
}

.wenzi {
  width: 1188px;
  height: 30px;
  margin: 0px auto;
  margin-bottom: 10px;
  margin-top: 50px;

  p {
    font-family: "Microsoft Yahei";
    font-size: 24px;
    float: left;

    span {
      font-size: 14px;
      color: #999;
      margin-left: 5px;
    }
  }
}

.zhuti {
  width: 1188px;
  height: 614px;
  margin: 0px auto;
  // overflow: hidden;

  em {
    display: inline-block;
    position: relative;
    top: -300px;
    left: -240px;
    width: 227px;
    height: 300px;
    background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    transform: skewx(-25deg);
    -o-transform: skewx(-25deg);
    -moz-transform: skewx(-25deg);
    -webkit-transform: skewx(-25deg);
  }
}

.zhuti_one {
  width: 948px;
  height: 300px;
  float: left;
  margin-bottom: 10px;

  div:nth-child(4) {
    margin-right: 0px;
  }

  &:nth-of-type(2) dl {
    margin-right: 2px;
  }

  dl {
    margin: 0px;
    padding: 0px;
    width: 227px;
    height: 300px;
    background-color: #fff;
    float: left;
    font-size: 14px;

    dt {
      margin: 18px 20px 18px 23px;

      img {
        width: 180px;
        height: 180px;
      }
    }

    dd {
      margin: 0px 20px 0px 23px;

      &:nth-of-type(3) {
        font-size: 24px;
        color: #ff442f;
        margin-top: 5px;
      }
    }
  }
}

.new-product {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.baokuan {
  width: 468px;
  height: 300px;
  background-color: #fff;
  float: left;

  ul {
    list-style: none;
    font-size: 18px;
    padding: 10px;

    li {
      float: left;
      padding: 13px 76px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.tus:hover em {
  left: 990px;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.shangpins {
  margin-right: 12px;
}

.ban {
  float: left;
  margin-right: 10px;

}

// 3个大类结束

// 限时秒杀开始
.aui-sale-count {
  position: relative;
  top: 20px;
  left: 16px;
  height: 40px;
}

.aui-sale-time-link:hover {
  color: #fff;
}

.aui-sale-count-item {
  position: relative;
  float: left;
  width: 50px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  opacity: 0.9;
  margin-right: 5px;

  span {
    position: relative;
    line-height: 40px;
    font-weight: bold;
    font-size: 16px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    height: 1px;
    background: #ff442f;
  }
}

.miao-sha {
  width: 100%;

  #MiaoSha_section {
    width: 1190px;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 50px;
    // padding-bottom: 35px;

    .width img {
      float: left;
      transition: all 0.3s ease-in-out;
      opacity: 0.7;
    }
  }
}

#MiaoSha_one {
  width: 190px;
  height: 275px;
  background-color: #ff442f;
  padding-top: 40px;
  float: left;

  p:nth-of-type(1) {
    font-size: 34px;
    color: #fff;
    text-align: center;
  }

  p:nth-of-type(2) {
    color: #fff;
    font-size: 20px;
    text-align: center;
    opacity: 0.5;
  }

  p:nth-of-type(3) {
    margin-top: 3px;
    text-align: center;
  }

  p:nth-of-type(4) {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 17px;
  }
}

#MiaoSha_two {
  display: flex;
  width: 808px;
  height: 275px;
  float: left;
  overflow: hidden;

  #kuan {
    width: 1600px;
    overflow: hidden;
  }

  dl {
    margin: 0px;
    padding: 0px;
    width: 201px;
    height: 275px;
    font-size: 12px;
    color: #666;
    float: left;
    background-color: #fff;
    border-right: 1px #eee solid;

    dt {
      margin: 0px;
      padding: 0px;
      margin: 38px 0px 28px 30px
    }

    dd {
      /* margin-left: 25px; */
      text-align: center;

      span:nth-of-type(1) {
        font-size: 23px;
        color: #ff442f;
      }

      span:nth-of-type(2) {
        text-decoration: line-through;
      }
    }
  }
}

#MiaoSha_Three {
  width: 191px;
  height: 275px;
  float: left;
  background-color: #fff;
  border-right: 1px #eee solid;

  ul {
    list-style: none;
    position: relative;
    top: -295px;
    text-align: center;
    z-index: 9999;

    li {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      background-color: white;
    }

    .curr {
      background-color: #39bf3e;
    }
  }
}

// 限时秒杀结束

// 分类开始
.type {
  width: 100%;
  margin-top: 50px;

  .type-content {
    display: flex;
    justify-content: space-between;
    width: 1190px;
    margin: 0 auto;

    .out-top {
      box-sizing: border-box;
      width: 284px;
      height: 360px;
      cursor: pointer;
      background-color: skyblue;
      background: #fff;
      box-shadow: 0 4px 14.88px 1.12px rgba(47, 46, 45, 0.08), inset 0 13px 29px 0 rgba(248, 245, 240, 0.71);
      border-radius: 10px;
      padding: 30px 25px 35px;
      transition: .3s linear;
    }
  }
}

.features-thumb {
  width: 193px;
  margin: 0 auto 50px;
  position: relative;

  img {
    width: 193px;
    height: 193px;
    border-radius: 50%;
    vertical-align: middle;
    border-style: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -37px;
    background-image: url(../../assets/img/type/features_shape.png);
    width: 60px;
    height: 7px;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
}

.features-content {
  text-align: center;

  h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: #7d7d7d;
    margin-bottom: 15px;
  }
}

.item-a:hover {
  color: #9cc623;
}

// 限时秒杀结束

// 轮播图开始
.banner {
  width: 100%;
  height: 500px;
  background-color: #fff;

  .banner-content {
    width: 1190px;
    margin: 0 auto;
  }
}

.banner-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// 轮播图结束

.index {
  width: 100%;
  background-color: #f7f7f7;
}

#zhuti1 ul li:nth-child(1):hover {
  color: #dd5353 !important;
  background-color: #ffcbcb !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

#zhuti2 ul li:nth-child(1):hover {
  color: #3ea82c !important;
  background-color: #cdffcc !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

#zhuti3 ul li:nth-child(1):hover {
  color: #3388cb !important;
  background-color: #cae5ff !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}</style>