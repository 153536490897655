<template>
   <div class="search">
      <Header></Header>
      <div class="search-content">
         <div id="shaixuan">
	        <div class="fenlei" id="SexFenLei">
	            <ul>
                  <span style="color:#9EA3A9">季节：</span>
                  <li value="全部" class="active">全部</li>
                  <li value="春">春季</li>
                  <li value="夏">夏季</li>
                  <li value="秋">秋季</li>
                  <li value="冬">冬季</li>
                  <input type="hidden" name="SexFenLei">
	            </ul>
	        </div>
	        <div class="fenlei" id="ma">
	            <ul>
                  <span style="color:#9EA3A9">口感：</span>
                  <li value="部" class="active">全部</li>
                  <li value="酸甜">酸甜</li>
                  <li value="软糯">软糯</li>
                  <li value="脆爽">脆爽</li>
                  <input type="hidden" name="ma">
	            </ul>
	        </div>
	        <div class="fenlei" style="border-bottom: 0px;" id="banxing">
	            <ul>
                  <span style="color:#9EA3A9">储藏方式：</span>
                    <li value="全部" class="active">全部</li>
                    <li value="冷藏">冷藏</li>
                    <li value="常温">常温</li>
                  <input type="hidden" name="banxing">
	            </ul>
	        </div>
	    
         </div>
         <div id="shaixuan2">
            <ul>
               <li style="background-color: #fff;border-right: 1px #e1e1e1 solid" class="sx">推荐</li>
               <li class="sx">热销</li>
               <li class="sx">惠农精选</li>
               <li class="sx">价格</li>
               <li style="padding: 13px 10px">
                  <input type="text" name="s" value="" placeholder="￥" style="margin-right: 5px">~
                     <input type="text" placeholder="￥" name="b"  value="" style="margin-left: 5px">
                     <p style="display: inline-block;border: 1px #e1e1e1 solid;padding: 1px 3px;background-color: #fff;margin-left: 5px" id="queren2">
                        确认
                     </p>
               </li>
            </ul>
         </div>
         <div id="sp">
            <dl v-for="(item, index) in productList.slice(0, 10)" 
               :key="index" 
               class="nan" 
               :title="item.name"
               @click=goToProductDetail(item.id)
            >
               <div style="width: 240px;height: 220px;overflow: hidden">
                  <dt style="margin-bottom: 0px;"><img :src="item.coverUrl" alt="" width="220px"></dt>
               </div>
               <dd>￥<span>{{ item.price }}</span><p>收藏</p></dd>
               <dd style="height: 20px;overflow: hidden;">{{ item.name }}</dd>
               <dd style="overflow: hidden; height: 20px;">{{ item.name }}</dd>
            </dl>
         </div>
         <div class="page">
            <el-pagination
               align="center"
               background
               layout="prev, pager, next"
               :total="20"
            >
            </el-pagination>
         </div>
      </div>
      <Footer></Footer>
   </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { searchByCategoryApi, searchByKeywordApi } from "@/api/product.js"

export default {
   data() {
      return {
         categoryId: 0,
         productList: [],
         keyword: ""
      }
   },
   components: {
      Header,
      Footer
   },
   created() {
      if (this.$route.query.categoryId != null) {
         this.categoryId = this.$route.query.categoryId
         this.__searchByCategory()
      } else {
         this.keyword = this.$route.query.keyword
         this.__searchByKeyword()
      }
   },
   watch: {
      '$route.query.categoryId': function (newCategoryId) {
         if (newCategoryId != null) {
            this.categoryId = newCategoryId;
            this.__searchByCategory();
         }
      },
      '$route.query.keyword': function (newKeyword) {
         if (newKeyword != null) {
            this.keyword = newKeyword;
            this.__searchByKeyword();
         }
      },
      // categoryId(newVal, oldVal) {
      //    console.log("this.categoryId变化了：", oldVal, newVal);
      //    // this.__searchByCategory()
      // }
   },
   methods: {
      async __searchByCategory() {
         const res = await searchByCategoryApi({ categoryId: this.categoryId });
         if (!res) return;
         this.productList = res.data
         // console.log(res);
      },
      async __searchByKeyword() {
         const res = await searchByKeywordApi({ keyword: this.keyword });
         if (!res) return;
         this.productList = res.data
      },
      goToProductDetail(id) {
         this.$router.push({ path: '/product', query: {productId: id} });
      }
   }
}
</script>

<style lang="less" scoped>

.active {
   color: #fff;
   background-color: #ff3366;
   border-radius: 3px;
   transition: all 0.2s ease-in-out;
}

.page {
   width: 100%;
}

#sp {
   margin: 0px;
   padding: 0px;
   width: 1190px;
   overflow: hidden;
   margin: 0px auto;
   margin-bottom: 50px;
   padding-bottom: 10px;
   

   dl {
      width: 233px;
      overflow: hidden;
      font-size: 14px;
      transition: all 1s ease-in-out;
      float: left;
      margin: 0px 2.5px;
      margin-top: 10px;
      box-shadow: 0px 2px 7px #e2e2e2;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;

      &:hover {
         box-shadow: 0px 0px 5px #666;
         border-radius: 3px;
         transition: all 0.2s ease-in-out;
      }

      dt {
         margin: 10px 10px;
         cursor: pointer;

         img {
            transition: all 1s ease-in-out;
         }
      }

      dd {
         margin: 0px;
         padding: 0px;
         margin-left: 10px;
         line-height: 24px;
         padding-right: 11px;
         cursor: pointer;

         &:nth-of-type(1) {
            font-size: 13px;
            color: orangered;
            margin-top: 10px;

            p {
               float: right;
            }

            span {
               font-size: 23px;
               color: #ff442f;
            }
         }

         &:nth-of-type(2) {
            font-weight: bold;
            color: #000;
         }
      }
   }
}

#sp dl:hover dt img{
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}

#sp dl:hover dd:nth-of-type(2){
    color: #FF3366;
    transition: all 0.2s ease-in-out;
}
#sp dl dd:nth-of-type(3){
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
}

#shaixuan2 {
   width: 1188px;
   height: 44px;
   margin: 0px auto;
   margin-top: 8px;
   background-color: #f8f8f8;
   border: 1px solid #e1e1e1;
   margin-bottom: 5px;

   ul {
      list-style: none;
      font-size: 12px;
      color: #666;

      li {
         margin: 0px;
         padding: 0px;
         float: left;
         padding: 14px 18px;

         input {
            margin: 0px;
            padding: 0px;
            width: 55px;
         }
      }
   }

   .sx:hover {
      color: #ff442f;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
   }
}

#shaixuan {
   width: 1188px;
   overflow: hidden;
   border: 1px #EFEFEF solid;
   margin: 0px auto;
   margin-top: 20px;
   border-radius: 5px;
}

.fenlei {
   width: 1150px;
   height: 38px;
   overflow: hidden;
   border-bottom: 1px #EFEFEF solid;
   margin-left: 18px;

   span {
      font-size: 12px;
      float: left;
   }

   ul {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      margin-top:11px;
      color: #666;

      p {
         float: right;
         cursor: pointer;
      }

      li {
         float: left;
         margin: 0px 10px;
         cursor: pointer;
         padding: 2px 5px;
         margin-top: -2px;
         transition:all 0.2s ease-in-out;

         &:hover {
            color: #fff;
            background-color: #ff3366;
            border-radius: 3px;
            transition: all 0.2s ease-in-out;
         }
      }
      
   }
}
.search {
   width: 100%;

   .search-content {
      width: 1190px;
      margin: 0 auto;
   }
}
</style>