import http from '@/utils/http'

// 添加收货地址
export const addAddressApi = (data) => http ({
   url: '/address/add',
   method: 'post',
   data: data
})

// 删除收货地址
export const delAddressApi = (data) => http ({
   url: '/address/del',
   method: 'get',
   params: data
})

// 修改收货地址
export const updateAddressApi = (data) => http ({
   url: '/address/update',
   method: 'post',
   data: data
})

// 获取收货地址列表
export const getAddressListApi = () => http ({
   url: '/address/list',
   method: 'get',
})
