<template>
   <div class="login">
      <div class="logo-content">
         <img src="../../assets/img/hng.png" @click="goTo" style="width: 190px;" alt="">
         <div class="goto">
            已有账号？<router-link to="/login"><span>请登录></span></router-link>
         </div>
      </div>
      <div class="register">
         <div class="login-container">
            <h1>新用户注册</h1>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
               <!-- 手机号码 -->
               <el-form-item label="手机号" prop="phone">
                  <el-input type="text" v-model="ruleForm.phone" autocomplete="off"></el-input>
               </el-form-item>
               <!-- 密码 -->
               <el-form-item :label="'密\xa0\xa0\xa0\xa0码'" prop="password">
                  <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
               </el-form-item>
               <!-- 验证码 -->
               <el-form-item label="验证码" prop="phoneCode">
                  <div class="captcha-box">
                     <el-input v-model.number="ruleForm.phoneCode" style="margin-right: 6px;"></el-input>
                     <el-button type="primary" @click="dialogVisible = true,changeCheckCode">点击获取验证码</el-button>
                  </div>
               </el-form-item>
               <el-form-item id="check" style="margin-bottom: 0px; margin-top: -16px;">
                  <el-checkbox-group v-model="ruleForm.isRead">
                     <el-checkbox label="我已阅读并且同意 惠农购 注册条款" name="type"></el-checkbox>
                  </el-checkbox-group>
               </el-form-item>
               <el-form-item id="login-btn-box">
                  <el-button class="login-btn" type="primary" @click="submitForm('ruleForm')">提交</el-button>
               </el-form-item>
            </el-form>
            <!-- 输入图形验证码的框 -->
            <el-dialog
               title="发送短信验证码"
               :visible.sync="dialogVisible"
               width="30%"
            >
               <div class="captcha-box">
                  <el-input v-model="ruleForm.captchacode" placeholder="请输入图形验证码"></el-input>
                  <img :src="captchaSrc" @click="changeCheckCode" />
               </div>
               <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="sendPhoneCode">确 定</el-button>
               </span>
            </el-dialog>
         </div>
      </div>
      <Footer></Footer>
   </div>
</template>
 
<script>
import Footer from '@/components/Footer.vue'
import { validateUsername } from '@/utils/validate'
import { getCaptchacodeApi, sendPhoneCodeApi, registerApi } from '@/api/user.js'

export default {
   data() {
      return {
         captchaSrc: "", // 图形验证码的路径
         dialogVisible: false,
         ruleForm: {
            phone: "",
            password: "",
            captchacode: "",
            phoneCode: "",
            isRead: []
         },
         rules: {
            phone: [
               {
                  required: true, // 必填项
                  message: "手机号不能为空！", // 提示语
                  trigger: "blur"
               },
               // 自定义校验的写法
               { validator: validateUsername, trigger: 'blur' }
            ],
            password: [
               {
                  required: true, // 必填项
                  message: "密码不能为空！", // 提示语
                  trigger: "blur"
               }
            ],
            phoneCode: [
               {
                  required: true, // 必填项
                  message: "验证码不能为空！", // 提示语
                  trigger: "blur"
               }
            ],
         },
      }
   },
   components: {
      Footer
   },
   created() {
      // 获取图形验证码
      this.changeCheckCode()
   },
   methods: {
      goTo() {
         this.$router.push("/")
      },
      async changeCheckCode() {
         this.ruleForm.captchacode = ""
         // 调用图形验证码接口
         const res = await getCaptchacodeApi()
         const img = btoa(new Uint8Array(res).reduce((res, byte) => res + String.fromCharCode(byte),""));
         this.captchaSrc = "data:image/png;base64," + img;
      },
      async sendPhoneCode() {
         // TODO 判断手机号码和验证码不能为空

         this.dialogVisible = false
         // 获取输入的图形验证码
         console.log("图形验证码：", this.ruleForm.captchacode);
         const res = await sendPhoneCodeApi({phone: this.ruleForm.phone, captcha: this.ruleForm.captchacode})
         if (res.code == 0) {
            this.$message({message: '短信验证码发送成功',type: 'success'});
         }
         console.log(res);
      },
      async register() {
         let data = {
            phone: this.ruleForm.phone,
            password: this.ruleForm.password,
            code: this.ruleForm.phoneCode
         }
         let res = await registerApi(data)
         if (res.code == 0) {
            this.$message({message: '注册成功',type: 'success'});
         }
      },
      submitForm(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               // 校验通过，走这里的代码，发起注册请求
               this.register()
            } else {
               // 校验错误
               this.$message({
                  message: '请输入正确的信息后再进行提交！',
                  type: 'warning'
               });
               console.log('error submit!')
               return false;
            }
         })
      },
   }
}
</script>
 
<style lang="less" scoped>
.goto {
   margin-bottom: 10px;

   span {
      color: #38ce60;
   }
}

.logo-content {
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
   width: 1190px;
   height: 97px;
   margin: 0px auto;
   box-sizing: border-box;
}

.login {
   width: 100%;
   height: 100%;

   .register {
      position: relative;
      width: 100%;
      height: 600px;
      background-image: url('@/assets/img/register-bg.jpg');
      background-size: cover;
      background-attachment: fixed;
   }

   .login-container {
      position: absolute;
      width: 400px;
      top: 100px;
      right: 300px;
      padding-top: 20px;
      padding-right: 40px;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 0 7px 3px rgba(0, 0, 0, .1);

      h1 {
         font-size: 20px;
         text-align: center;
         margin-bottom: 20px;
         padding-left: 40px;
      }

      .captcha-box {
         display: flex;

         img {
            width: 100px;
            height: 40px;
            margin-left: 20px;
         }
      }

      .login-btn {
         width: 100%;
      }

      // 样式覆盖的写法
      ::v-deep #login-btn-box .el-form-item__content {
         margin-left: 40px !important;
      }

      ::v-deep #check .el-form-item__content {
         margin-left: 38px !important;
      }

      // ::v-deep .el-form-item #check  {
      //    margin-bottom: 0px;
      // }
   }
}
</style>