<template>
   <div id="box" :style="{width: widthSize + 'px'}">
      <ul ref="scrollList">
         <li
            :style="{width: widthSize + 'px', height: widthSize + 'px'}"
            v-for="(item, index) in viewShopList"
            :key="index"
            @mouseover="hoveredIndex = index"
            @mouseout="hoveredIndex = null"
         >
            <img v-if="item && item.url" :src="item.url" :width="widthSize" :height="widthSize"/>
            <div class="hei" :style="{width: widthSize + 'px', height: widthSize + 'px', display: (hoveredIndex === index ? 'block' : 'none')}">
               <p>点击查看</p>
            </div>
         </li>
      </ul>
   </div>
</template>

<script>
export default {
   props: {
      widthSize: {
         type: Number,
         default: 200
      }
   },
   data() {
      return {
         currentIndex: 0,
         hoveredIndex: null,
         intervalId: null,
         isPaused: false,
         viewShopList: [
            {
               url: require("../assets/img/viewshop/k1.jpg")
            },
            {
               url: require("../assets/img/viewshop/k2.jpg")
            },
            {
               url: require("../assets/img/viewshop/k3.jpg")
            },
            {
               url: require("../assets/img/viewshop/k4.jpg")
            },
            {
               url: require("../assets/img/viewshop/k5.jpg")
            },
            {
               url: require("../assets/img/viewshop/k6.jpg")
            },
         ]
      }
   },
   computed: {
      displayedShopList() {
         return this.viewShopList.slice(this.currentIndex, this.currentIndex + 3);
      }
   },
   mounted() {
      this.$nextTick(() => {
         this.initAnimation();
      });
   },
   watch: {
      hoveredIndex(newValue) {
         this.isPaused = newValue !== null;
      },
   },
   methods: {
      initAnimation() {
         const startAnimation = () => {
         const box = this.$refs.scrollList;
         if (!box) {
            console.error('scrollList is not available');
            return;
         }

         const totalHeight = box.scrollHeight;
         box.style.position = 'relative';

         this.intervalId = setInterval(() => {
            if (!this.isPaused) {
               let currentTop = parseFloat(box.style.top) || 0;
               let newTop = (currentTop - 1) % totalHeight;
               box.style.top = newTop + 'px';
            }
         }, 20);
         };

         if (!this.$refs.scrollList) {
            this.$nextTick(() => {
               if (this.$refs.scrollList) {
                  startAnimation();
               }
            });
         } else {
            startAnimation();
         }
      },
   },
   beforeDestroy() {
      clearInterval(this.intervalId);
   },
}
</script>

<style lang="less" scoped>
#box {
   width: 200px;
   height: 558px;
   overflow: hidden;
   margin: 0px auto;

   ul {
		margin: 0px;
		padding: 0px;
		list-style: none;
      position: absolute; /* 使ul的定位为绝对，方便动画 */

      li {
         position: relative; // 
         width: 200px;
         height: 200px;
         overflow: hidden;
         margin-top: 5px;
         cursor: pointer;
      }
	}
}

.hei {
   // width: 200px;
   // height: 200px;
   background-color: black;
   position: absolute;
   // top: -203px;
   top: 0;
   opacity: 0.5;
   color: #fff;
   display: none;
   text-align: center;
   line-height: 200px;
   transition: opacity 0.3s;

   p {
		text-align: center;
		line-height: 200px;
	}

   .show {
      display: block;
   }
}
</style>