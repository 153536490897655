<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>

html, body, #app {
  width: 100%;
  height: 100%;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

a {
  text-decoration: none;
  color: #606266;
}

div {
  box-sizing: border-box;
}

.el-button--primary {
  background-color: #38bf43 !important;
  border-color: #38bf43 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #37bf3e !important;
}
</style>
