<template>
   <div class="footer">
      <div id="footer_manger">
         <div id="footer_back">
            <ul>
                  <li>卖家帮助</li>
                  <li class="style">新手指南</li>
                  <li class="style">服务保障</li>
                  <li class="style">常见问题</li>
                  <li class="style">风险监测</li>
            </ul>
            <ul>
                  <li>商家帮助</li>
                  <li class="style">供货商招募</li>
                  <li class="style">商家后台</li>
            </ul>
            <ul>
                  <li>关于我们</li>
                  <li class="style">关于惠农</li>
                  <li class="style">联系我们</li>
            </ul>
            <ul>
                  <li>产品服务</li>
                  <li class="style">找供应</li>
                  <li class="style">找采购</li>
                  <li class="style">看行情</li>
                  <li class="style">发布供应</li>
            </ul>
            <dl>
               <dt>惠农微信账号</dt>
               <dd><img src="../assets/img/wx.jpg" alt="" width="100px"></dd>
            </dl>
            <dl>
               <dt></dt>
               <dd></dd>
            </dl>
         </div>
         <p>友情链接：
            <a href="#">农产品搜索</a>
            <span>|</span>
            <a href="#">惠农官网</a>
            <span>|</span>
            <a href="#">品牌加盟网</a>
            <span>|</span>
            <a href="#">商标查询</a>
            <span>|</span>
            <a href="#">大蒜批发</a>
            <span>|</span>
            <a href="#">生鲜配送系统</a>
            <span>|</span>
            <a href="#">科技新闻</a>
            <span>|</span>
            <a href="#">农敢网</a>
            <span>|</span>
            <a href="#">招聘</a>
            <span>|</span>
            <a href="#">农资代理</a>
         </p>
         <p>土地流转网&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="#">e采购</a>
            <span>|</span>
            <a href="#">中国花木网</a>
            <span>|</span>
            <a href="#">农产品采购</a>
            <span>|</span>
            <a href="#">茶友网</a>
            <span>|</span>
            <a href="#">食品商务网</a>
            <span>|</span>
            <a href="#">葡萄酒</a>
            <span>|</span>
            <a href="#">惠农优评</a>
            <span>|</span>
            <a href="#">乡村振兴合作</a>
         </p>
         <p>Copyright ©
            <!-- 电信与信息服务业务经营许可证100798号 -->
            经营性网站备案信息
            <a href='https://www.xbeian.com/1-9OoOUnMlV9wEMzwA.html' target='_black'>闽ICP备2023000775号-1</a>
         <p>本网站直接或间接向消费者推销商品或者服务的商业宣传均属于“广告”（包装及参数、售后保障等商品信息除外）</p>
         <p>客服QQ：2908476336  文明办网文明上网举报电话：123-12345678  违法不良信息举报中心</p>
         </p>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {

      }
   },
}
</script>

<style lang="less" scoped>

a {
   color: #666;
   text-decoration: none;
}

.footer{
    width: 100%;
    overflow:hidden;
    background-color: #F5F5F5;
    border-top:1px #ddd solid;
    margin-top: 15px;/*仅用于测试 往页脚上 加的   100px外边距  用的时候 可以删掉*/

}
.footer #footer_manger{
    width: 1190px;
    overflow:hidden;
    margin: 0px auto;
}
.footer  #footer_manger #footer_back{
    width: 1190px;
    overflow:hidden;
    padding-top: 28px;
    border-bottom:1px #ddd solid;
}

#footer_back ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 12px;
    color: #666666;
    float: left;
    margin-right: 105px;
    margin-left: 15px;
}
#footer_back ul li{
    margin-bottom: 20px;
}
#footer_back ul:nth-of-type(4) li{
}
#footer_back ul:nth-of-type(4) li img{
    vertical-align:middle;
    padding-right: 5px;
}
#footer_back ul li:nth-of-type(1){
    font-size: 16px;
    font-weight: bold;
}

#footer_back dl{
    margin: 0px;
    padding:0px;
    font-size: 16px;
    font-weight: bold;
    color: #666;float: left;

}
#footer_back dt{
    margin-bottom: 15px;
}
#footer_back dd{
    text-align: center;
}
#footer_back dl:nth-of-type(1){
    margin-left: 120px;
}




#footer_manger p{
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
    color: #666;
}
#footer_manger p:nth-of-type(1){
    margin-top: 12px;
}
#footer_manger p:nth-of-type(1) a{
    text-decoration: none;
    color: #666;
}
#footer_manger p:nth-of-type(1) span{
    color: #666;
    margin: 0px 3px;
}
#footer_manger p:nth-of-type(4){
    margin-bottom: 15px;
}

#footer_manger p a:hover{
    text-decoration: underline;
}
#footer_manger .style:hover{
    text-decoration: underline;
    cursor: pointer;
}
#footer_manger p:nth-of-type(2) span{
    color: #666;
    margin: 0px 3px;
}
</style>