import http from '@/utils/http'

// 获取导航栏列表
export const getNavListApi = () => http ({
   url: '/index/nav-list',
   method: 'get',
})

// 获取轮播图列表
export const getBannerListApi = () => http ({
   url: '/index/banner-list',
   method: 'get',
})

// 获取首页分类下的商品
export const getproductListApi = () => http ({
   url: '/index/product-list',
   method: 'get',
})