<template>
   <div class="product">
      <Header :show="false"></Header>
      <div class="product-content">
         <div id="one">
            <!-- 左部商品图片列表 -->
            <div id="left">
               <div class="lanrenzhijia">
                  <!-- 大图begin -->
                  <div id="preview" class="spec-preview">
                     <span class="jqzoom">
                        <img v-if="productDetail.imgList" :src="productDetail.imgList[currentIndex]" width="350px;"
                           height="350px;" />
                     </span>
                  </div>
                  <!-- 大图end -->
                  <!-- 缩略图begin -->
                  <div class="spec-scroll">
                     <a class="prev" @click="prev">&lt;</a>
                     <a class="next" @click="next">&gt;</a>
                     <div class="items">
                        <ul>
                           <li v-for="(imgUrl, index) in productDetail.imgList" :key="index">
                              <img :src="imgUrl" @mousemove="preview(imgUrl)">
                           </li>
                        </ul>
                     </div>
                  </div>
                  <!-- 缩略图end -->
               </div>
            </div>
            <!-- 中间商品详情 -->
            <div id="zhong">
               <p id="title">{{ productDetail.name }}</p>
               <span style="color:#f60;font-size: 14px;margin-left: 5px">当季新品</span>
               <div id="xx">
                  <div id="hui">
                     <dl>
                        <dt>现&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;价</dt>
                        <dd v-if="productDetail.specList"><span>￥</span>{{ productDetail.specList[curSpecIndex].price }}
                        </dd>
                     </dl>
                     <dl>
                        <dt>领&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;券</dt>
                        <dd style="cursor: pointer;">
                           <img src="../../assets/img/quan.png" alt="">
                        </dd>
                     </dl>
                     <dl style="margin-top: 13px;">
                        <dt>促&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;销</dt>
                        <dd style="margin-top: 1px">
                           <span class="shi">实名有礼</span>
                           <span style="font-size: 12px;color: #666;margin-left: 10px;float: left;font-weight: 400">
                              实名认证领15元无门槛易券
                           </span>
                           <p
                              style="font-size: 12px;color: #2272cb;text-decoration: underline;font-weight: 400;float: left;margin-left: 10px;cursor: pointer;">
                              查看
                           </p>
                        </dd>
                     </dl>
                  </div>

                  <dl style="margin-left: 6px; margin-top: 10px">
                     <dt>送&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至</dt>
                     <dd>
                        <span style="font-size: 12px;color: #666;float: left;font-weight: 400;margin-top: 12px;">
                           实名认证领15元无门槛易券
                        </span>
                     </dd>
                     <br />
                     <dd>
                        <span
                           style="font-size: 12px;color: #666;float: left;font-weight: 400;margin-top: 13px;clear:both;">
                           由惠农专营店 从 厦门市 销售和发货，并提供售后服务
                        </span>
                     </dd>
                  </dl>
               </div>
               <div id="xia">
                  <dl>
                     <dt>规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格</dt>
                     <dd>
                        <div class="guige" @click="curSpecIndex = index" v-for="(item, index) in productDetail.specList"
                           :key="index" :class="{ 'guige-active': (index == curSpecIndex) }">
                           <p class="chicun">{{ item.name }}</p>
                           <p class="yanse">{{ item.unit }}</p>
                        </div>
                     </dd>
                  </dl>
                  <dl>
                     <dt>数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量</dt>
                     <dd>
                        <input @click="count--" type="button" value="-" style="margin-right: -5px; width: 20px;"
                           name="jian">
                        <input v-model="count" type="text" value="1" name="count" style="width: 70px; text-align: center;">
                        <input @click="count++" type="button" value="+" style="margin-left: -5px; width: 20px;" name="jia">
                     </dd>
                  </dl>
               </div>
               <div id="anniu">
                  <p @click="toOrder" id="lijigoumai" style="cursor: pointer;">立即购买</p>
                  <p @click="__addCart" id="jiaru"><img src="../../assets/img/gwc.png" alt="">加入购物车</p>
               </div>
            </div>
            <!-- 右部看了又看 -->
            <div id="right">
               <h5 style="margin-left: 15px;margin-top: 10px;margin-bottom: 5px;">看了又看</h5>
               <ViewShop></ViewShop>
            </div>
         </div>
         <div id="xiangqing">
            <div id="zuoce">
               <div id="shangjia">
                  <div id="biaoti">
                     <p><img src="../../assets/img/shang.png" alt="">惠农商家</p>
                  </div>
                  <dl style="width: 200px;">
                     <dt>商家：</dt>
                     <dd>惠农商家</dd>
                  </dl>
                  <dl style="width: 200px;">
                     <dt>邮箱：</dt>
                     <dd>
                        2908476336@qq.com
                     </dd>
                  </dl>
                  <dl>
                     <dt>电话：</dt>
                     <dd>18050650153</dd>
                  </dl>
                  <hr style="border: 0px;border-bottom: 1px #ccc solid;width: 170px;margin: 0px auto;margin-top: 12px;" />
                  <ul>
                     <li>商品</li>
                     <li>4.96 <img src="../../assets/img/jiantou.png" alt=""></li>
                  </ul>
                  <ul>
                     <li>服务</li>
                     <li>4.96 <img src="../../assets/img/jiantou.png" alt=""></li>
                  </ul>
                  <ul>
                     <li>物流</li>
                     <li>4.96 <img src="../../assets/img/jiantou.png" alt=""></li>
                  </ul>
                  <span style="color: #39bf3e;border: 1px #39bf3e solid">进入店铺</span>
                  <span>收藏店铺</span>
               </div>
               <div id="cainixihuan">
                  <p>猜你喜欢</p>
                  <ViewShop :widthSize="180"></ViewShop>
               </div>
            </div>
            <div id="neirong">
               <div id="jieshao">
                  <div style="width: 988px;">
                     <el-tabs id="daohangs" v-model="activeName">
                        <el-tab-pane class="hh" style="margin-left: 20px;!important" label="商品详情" name="first">
                           {{ productDetail.detail }}
                        </el-tab-pane>
                        <el-tab-pane label="包装及参数" name="second">包装及参数</el-tab-pane>
                        <el-tab-pane :label="'评论（' + commentList.length + '）'" name="third">
                           <div class="pp" v-if="commentList" v-for="(item, index) in commentList" :key="index">
                              <div class="user">
                                 <img :src="item.avatar" alt=""><br>
                                 <div>{{ item.username }}</div>
                              </div>
                              <!-- style="position: relative;" -->
                              <div class="right">
                                 <div class="rate">
                                    <el-rate
                                       v-model="item.rating"
                                       disabled
                                       text-color="#ff9900"
                                       score-template="{value}">
                                    </el-rate>
                                 </div>
                                 <div class="pl-content">
                                    {{ item.content }}
                                 </div>
                                 <div class="info-bottom">
                                    <div class="gg">
                                       数量：{{ item.buyNum }} 规格：{{ item.spec }}
                                    </div>
                                    <div class="time">
                                       {{ item.createTime }}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </el-tab-pane>
                        <el-tab-pane label="售后保障" name="fourth">售后保障</el-tab-pane>
                     </el-tabs>
                  </div>
                  <div id="img">

                  </div>
               </div>
            </div>
         </div>
      </div>
      <Footer></Footer>
   </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import ViewShop from "@/components/ViewShop.vue"
import { getProductDetailApi, getCommentListApi, getTopSellingListApi } from "@/api/product.js"
import { addCartApi } from "@/api/cart.js"

export default {
   data() {
      return {
         value: 4,
         activeName: 'first',
         productId: 1,
         price: 0,
         count: 1,
         curSpecIndex: 0,
         currentIndex: 0,
         productDetail: {},
         commentList: [],
         topSellingList: []
      }
   },
   components: {
      Header,
      Footer,
      ViewShop
   },
   computed: {

   },
   created() {
      this.productId = this.$route.query.productId
      this.__getProductDetail()
      this.__getCommentList()
      // this.__getTopSellingList()
   },
   watch: {
      count(newVal, oldVal) {
         if (newVal < 1) {
            this.$message({ message: '数量不能小于1', type: 'warning' });
            this.count = 1;
         }
      },
      '$route.query.product': function (newProductId) {
         if (newProductId != null) {
            this.keyword = newProductId;
            this.__getProductDetail();
         }
      },
   },
   methods: {
      prev() {
         if (this.currentIndex > 0) {
            this.currentIndex--;
         } else {
            this.currentIndex = this.productDetail.imgList.length - 1;
         }
      },
      next() {
         if (this.currentIndex < this.productDetail.imgList.length - 1) {
            this.currentIndex++;
         } else {
            this.currentIndex = 0;
         }
      },
      preview(url) {
         let index = -1;
         for (let i = 0; i < this.productDetail.imgList.length; i++) {
            if (this.productDetail.imgList[i] === url) {
               index = i;
               break;
            }
         }
         this.currentIndex = index;
      },
      async __getProductDetail() {
         const res = await getProductDetailApi({ productId: this.productId });
         if (!res) return;
         this.productDetail = res.data
         this.price = this.productDetail.specList[0].price
         // console.log(res);
      },
      async __getCommentList() {
         const res = await getCommentListApi({ productId: this.productId });
         if (!res) return;
         console.log(res);
         this.commentList = res.data;
      },
      async __addCart() {
         let data = {
            productId: this.productDetail.id,
            specId: this.productDetail.specList[this.curSpecIndex].id,
            quantity: this.count
         }
         const res = await addCartApi(data);
         if (!res) return;
         this.$message({ message: '添加购物车成功', type: 'success' });
         // console.log("data: ", data);
      },
      async __getTopSellingList() {
         const res = await getTopSellingListApi();
         if (!res) return;
         this.topSellingList = res.data;
      },
      toOrder() {
         let data = [{
            productId: this.productDetail.id,
            storeId: this.productDetail.storeId,
            name: this.productDetail.name,
            coverUrl: this.productDetail.coverUrl,
            price: this.productDetail.specList[this.curSpecIndex].price,
            quantity: this.count,
            specId: this.productDetail.specList[this.curSpecIndex].id,
            specName: this.productDetail.specList[this.curSpecIndex].name
         }]
         this.$router.push({ 
            name: 'pay', 
            params: { selectedItems: data } 
         });
      }
   }
}
</script>

<style lang="less" scoped>
.pp {
   display: flex;
   justify-content: flex-start;
   padding: 0 20px 20px;
   border-bottom: 1px solid #f5f5f5;

   .user {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 54px;
      margin-right: 52px;
      font-size: 12px;

      img {
         width: 52px;
          height: 52px;
          display: block;
          border-radius: 50%;
      }

   }

   .right {
      width: 846px;

      .pl-content {
         margin: 10px 0;
         color: #727272;
         line-height: 18px;
      }

      .info-bottom {
         display: flex;
         justify-content: space-between;
         font-size: 12px;
         color: #909090;

      }
   }
}

/deep/ .el-tabs__item.is-active {
   color: #39bf3e;

}

/deep/ .el-tabs__active-bar {
   // border-bottom: none !important;
   bottom: none;
   top: 0;
   background-color: #39bf3e !important;
}

/deep/ .el-tabs__item:hover {
   color: #39bf3e;
}

/deep/ .el-tabs__nav-wrap {
   background-color: #f7f7f7;
}

.guige-active {
   border: 1px solid #ff442f !important;
}

.guige {
   display: flex;
   justify-content: space-between;
   width: 100%;
   margin-bottom: 6px;
}


.zhu {
   width: 769px;
   overflow: hidden;
   float: left;

   p {
      width: 769px;
      overflow: hidden;
      font-size: 12px;
      margin-top: 5px;
      line-height: 23px;
   }
}

#neirong {
   width: 986px;
   overflow: hidden;
   float: left;
   margin-left: 10px;
}

#img {
   width: 986px;
   overflow: hidden;
   text-align: center;
   margin-top: 50px;
}

#pl {
   width: 984px;
   overflow: hidden;
   border: 1px #ccc solid;
   margin-bottom: 20px;

   .ping {
      font: 700 14px "microsoft yahei";
      background-color: #f7f7f7;
      height: 41px;
      line-height: 41px;
      padding-left: 20px;
   }
}

#k {
   margin: 0px;
   padding: 0px;
   width: 961px;
   height: 30px;
   margin: 0px auto;
   margin-bottom: 10px;

   p {
      display: inline-block;
      font-size: 24px;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
   }

   span {
      padding: 5px 10px;
      background: #39bf3e;
      color: #fff;
      font-size: 14px;
      float: right;
      margin-top: 2px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
         background: #0dab12;
      }
   }
}

#pinglun {
   width: 984px;
   overflow: hidden;
   border: 1px #ccc solid;
   padding-bottom: 20px;
   margin-bottom: 20px;

   .ping {
      font: 700 14px "microsoft yahei";
      background-color: #f7f7f7;
      height: 41px;
      line-height: 41px;
      padding-left: 20px;
   }
}

#biaoqing:hover {
   color: #FF3366;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
}

#jieshao {
   width: 984px;
   overflow: hidden;
   border-left: 1px #ccc solid;
   border-right: 1px #ccc solid;
   border-bottom: 1px #ccc solid;
   margin-bottom: 10px;

   #daohangs {
      font-size: 14px;
      color: #222;
      list-style: none;

      li {
         float: left;
         padding: 14px 30px;

         &:hover {
            color: #39bf3e;
            cursor: pointer;
         }
      }
   }
}

#xiangqing {
   width: 1190px;
   height: 1000px;
   margin: 0px auto;
}

#cainixihuan {
   width: 190px;
   overflow: hidden;
   border: 1px #ddd solid;
   margin-top: 10px;

   p {
      font-size: 14px;
      color: #666;
      padding-top: 7px;
      padding-left: 10px;
      padding-bottom: 7px;
      border-bottom: 1px #ddd solid;
   }
}

#biaoti {
   width: 170px;
   height: 20px;
   border-bottom: 1px #39bf3e solid;
   margin: 0px auto;
   margin-top: 5px;
   margin-bottom: 20px;

   p {
      font-size: 14px;
      font-weight: bold;
      color: #2188ef;
      position: relative;
      bottom: -8px;
      z-index: 999;
      background-color: #fff;
      display: inline-block;
      width: 90px;
      text-align: center;
      left: 25%;

      img {
         vertical-align: middle;
      }
   }
}

#zuoce {
   width: 190px;
   height: 1000px;
   float: left;

   #shangjia {
      width: 190px;
      height: 243px;
      border: 1px #39bf3e solid;

      dl {
         margin: 0px;
         padding: 0px;
         font-size: 12px;
         width: 118px;
         height: 18px;
         margin-left: 10px;
         margin-top: 10px;

         dt {
            float: left;
            color: #999;
         }

         dd {
            float: left;
            color: #666;

            img {
               vertical-align: middle;
            }
         }
      }

      ul {
         list-style: none;
         margin: 0px;
         padding: 0px;
         font-size: 14px;
         color: #666;
         width: 62px;
         overflow: hidden;
         float: left;
         margin-top: 10px;

         li {
            text-align: center;

            &:nth-of-type(2) {
               font-size: 12px;
               color: #ff6600;
               margin-top: 1px;
            }
         }
      }

      span {
         display: inline-block;
         padding: 5px 10px;
         border: 1px #ccc solid;
         font-size: 12px;
         border-radius: 3px;
         margin-top: 15px;

         &:hover {
            color: #39bf3e;
            border: 1px #39bf3e solid;
            cursor: pointer;
         }

         &:nth-of-type(1) {
            margin-left: 18px;
            margin-right: 10px;
         }
      }
   }
}

#right {
   width: 224px;
   height: 596px;
   float: left;
}

#jiaru {
   width: 135px;
   height: 40px;
   background-color: #ff442f;
   color: #fff;
   font-weight: 600;
   text-align: center;
   line-height: 40px;
   border-radius: 5px;
   float: left;
   cursor: pointer;

   img {
      vertical-align: middle;
      margin-right: 3px;
   }
}

#anniu {
   width: 375px;
   overflow: hidden;
   margin-left: 75px;
}

#lijigoumai {
   width: 115px;
   height: 40px;
   background-color: #663200;
   font-size: 16px;
   color: #fff;
   font-weight: 600;
   text-align: center;
   line-height: 40px;
   border-radius: 5px;
   margin-right: 10px;
   float: left;
}

#xia {
   width: 575px;
   height: 178px;
   margin-left: 5px;
   padding-top: 7px;

   dl {
      margin: 0px;
      padding: 0px;
      list-style: none;
      margin-left: 5px;
      clear: both;

      &:nth-of-type(2) {
         dt {
            line-height: 35px;
         }

         dd {
            height: 30px;
            line-height: 30px;

            p {
               font-size: 12px;
               display: inline-block;
               padding: 7px 7px;
               border: 1px #ccc solid;
               transition: all 0.2s ease-in-out;
               cursor: pointer;

               &:hover {
                  border: 1px #39bf3e solid;
                  transition: all 0.2s ease-in-out;
               }
            }
         }
      }

      &:nth-of-type(3) {
         margin-top: 30px;

         dt {
            line-height: 28px;
         }

         dd {
            height: 30px;

            [type=button] {
               width: 25px;
               height: 30px;
               margin: 0px;
               padding: 0px;
            }

            [type=text] {
               margin: 0px;
               padding: 0px;
               width: 30px;
               height: 28px;
               text-align: center;
               border: 0px;
               border-bottom: 1px #ccc solid;
               border-top: 1px #ccc solid;
               outline: none;
            }
         }
      }

      dt {
         font: 12px/1.5 tahoma, arial, \5b8b\4f53;
         color: #666;
         float: left;
         margin-left: 10px;
         /*line-height: 40px;*/
      }

      dd {
         display: inline-block;
         width: 500px;
         /*height: 80px;*/
         margin-left: 15px;

         div {
            height: 38px;
            border: 1px #ddd solid;
            float: left;
            margin-right: 5px;
            transition: all 0.2s ease-in-out;

            p {
               font-size: 12px;
               float: left;
               line-height: 38px;
               padding: 0px 20px;
            }
         }
      }
   }
}

#zhong {
   width: 590px;
   overflow: hidden;
   padding-bottom: 20px;
   float: left;
   margin-left: 5px;

   #title {
      display: inline-block;
      width: 575px;
      height: 40px;
      font-size: 16px;
      color: #222;
      font-weight: bold;
      margin: 0px 5px;
      margin-top: 15px;
   }
}

#xx {
   width: 575px;
   height: 172px;
   border-bottom: 1px #ddd solid;
   margin-left: 5px;
   margin-top: 10px;

   dl {
      dt {
         font: 12px/1.5 tahoma, arial, \5b8b\4f53;
         color: #666;
         float: left;
         margin-left: 10px;
      }

      dd {
         font-size: 25px;
         font-weight: bold;
         color: #ff442f;
         float: left;
         margin-top: -10px;
         margin-left: 15px;

         span {
            font-size: 20px;
         }

         .shi {
            color: #ff442f;
            border: 1px #ff442f solid;
            font-size: 12px;
            margin-top: -1px;
            font-weight: 400;
            float: left;
         }
      }
   }

   &:nth-of-type(2) dd {
      margin-top: -5px;

      &:nth-of-type(2) {
         margin: 0px;
         padding: 0px;
         border: 1px #ff442f solid;

         span {
            margin: 0px;
            padding: 0px;
            /*margin-top: -5px;*/
         }
      }
   }
}

#hui {
   width: 575px;
   background-color: #F5F5F5;
   padding-top: 20px;
   padding-bottom: 10px;

   dl {
      height: 20px;
      margin: 0px;
      padding: 0px;
      list-style: none;
      margin-left: 5px;
      clear: both;

      &:nth-of-type(2) {
         margin-top: 10px;

         span {
            font-size: 12px;
            color: #38d;
            font-weight: 400;
            text-decoration: underline;
            line-height: 10px;
            margin-top: -20px;
         }
      }
   }
}

#one {
   width: 1190px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 20px;
}

#left {
   width: 360px;
   height: 596px;
   padding-top: 10px;
   float: left;
}

.product {
   width: 100%;

   .product-content {
      width: 1190px;
      margin: 0 auto;
   }
}

.lanrenzhijia {
   width: 352px;
   height: auto;
   margin: 0 auto;
   overflow: hidden;
}

.jqzoom {
   float: left;
   border: none;
   position: relative;
   padding: 0px;
   cursor: pointer;
   margin: 0px;
   display: block;
}

.jqzoom img {
   background: url(http://www.lanrenzhijia.com/demo/images/loading.gif) no-repeat center center;
}

.zoomdiv {
   z-index: 100;
   position: absolute;
   top: 0px;
   left: 0px;
   width: 350px;
   height: 350px;
   background: #ffffff;
   border: 1px solid #CCCCCC;
   display: none;
   text-align: center;
   overflow: hidden;
}

.jqZoomPup {
   z-index: 10;
   visibility: hidden;
   position: absolute;
   top: 0px;
   left: 0px;
   width: 20px;
   height: 20px;
   border: 1px solid #aaa;
   background: #ffffff
      /*url(../images/zoom.png) 50% center no-repeat*/
   ;
   opacity: 0.5;
   -moz-opacity: 0.5;
   -khtml-opacity: 0.5;
   filter: alpha(Opacity=50);
}

/*图片小图预览列表*/
.spec-preview {
   width: 350px;
   height: 350px;
   border: 1px solid #DFDFDF;
}

.spec-scroll {
   clear: both;
   margin-top: 5px;
   width: 352px;
}

.spec-scroll .prev {
   float: left;
   margin-right: 4px;
}

.spec-scroll .next {
   float: right;
}

.spec-scroll .prev,
.spec-scroll .next {
   display: block;
   font-family: "宋体";
   text-align: center;
   width: 10px;
   height: 54px;
   line-height: 54px;
   border: 1px solid #CCC;
   background: #EBEBEB;
   cursor: pointer;
   text-decoration: none;
}

.spec-scroll .items {
   float: left;
   position: relative;
   width: 322px;
   height: 56px;
   overflow: hidden;
}

.spec-scroll .items ul {
   position: absolute;
   width: 999999px;
   height: 56px;
   list-style: none
}

.spec-scroll .items ul li {
   float: left;
   width: 64px;
   text-align: center;
}

.spec-scroll .items ul li img {
   border: 1px solid #CCC;
   padding: 2px;
   width: 50px;
   height: 50px;
}

.spec-scroll .items ul li img:hover {
   border: 2px solid #39bf3e;
   padding: 1px;
}
</style>