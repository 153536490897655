import { getUserInfoApi } from '@/api/user.js'

export default {
   namespaced: true,
   state: {
      // 用户信息 做 localStrange本地存储
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {
         id: null,
         username: null,
         phone: null,
         avatar: null,
         role: null,
         sex: null
      }
   },
   mutations: {
      changeUserInfo(state, payload) {
         state.userInfo = payload
         localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
      }
   },
   actions: {
      async asyncChangeUserInfo({commit}, payload) {
         let res = await getUserInfoApi();
         let userInfo = res.data
         // console.log("userInfo", userInfo);
         if (!userInfo) return;
         commit("changeUserInfo", {
            id: userInfo.id,
            username: userInfo.username,
            phone: userInfo.phone,
            avatar: userInfo.avatar,
            role: userInfo.role,
            sex: userInfo.sex
         })
      }
   }
}