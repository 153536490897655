<template>
   <div class="home">
      <Header :homeShow="true"></Header>
      <div class="home-content">
         <div id="body">
            <!-- 菜单栏 -->
            <div id="left">
               <dl>
                  <!-- (<span name="count">5</span>) -->
                  <dt class="bb"><i class="el-icon-chat-line-round"></i>我的评论</dt>
               </dl>
               <dl>
                  <dt class="bb"><i class="el-icon-shopping-cart-2"></i>我的购物车</dt>
               </dl>
               <dl>
                  <dt class="bb"><i class="el-icon-location-outline"></i>我的收货地址</dt>
               </dl>
               <dl>
                  <!-- <dt><img alt="" src="../../assets/img/xq.gif">个人中心</dt> -->
                  <dt><i class="el-icon-user"></i>个人中心</dt>
                  <dd><a @click="dialogPwdVisible = true" class="UpdatePass">修改密码</a></dd>
                  <dd><a @click="dialogLogoutVisible = true">退出登录</a></dd>
               </dl>
               <dl>
                  <dt @click="goToOrder"><i class="el-icon-sell"></i>我的订单</dt>
                  <dd><a @click="goToOrder">待付款</a></dd>
                  <dd><a @click="goToOrder">待发货</a></dd>
                  <dd><a @click="goToOrder">待收货</a></dd>
                  <dd><a @click="goToOrder">待评论</a></dd>
               </dl>
            </div>
            <!-- 右部 -->
            <div id="right">
               <div class="info">
                  <dl>
                     <dt style="cursor: pointer;">
                        <img alt="" title="修改资料" :src="user.avatar" class="yuan" width="94px">
                     </dt>
                     <dd style="font-weight: bold">{{ user.username }}</dd>
                     <dd>手机号码：{{ user.phone }}</dd>
                     <!-- 邮箱 -->
                     <dd>居住地址：福建省厦门市</dd>
                  </dl>
                  <div class="set">
                     <i class="el-icon-s-tools"></i>
                     个人设置
                  </div>
               </div>
               <div id="zhu">
                  <div id="shop">
                     <div id="tishi2">
                        <img src="../../assets/img/Shop.png" width="90px;">
                        <p>您的店铺还未开通哦！~</p>
                        <span onclick="kaitong()">开通店铺</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- 修改密码 -->
      <el-dialog
         title="修改密码"
         :visible.sync="dialogPwdVisible"
         width="30%"
         :before-close="closePwd"
      >
         <el-form :model="updatePwdForm" :rules="pwdRules" ref="updatePwdForm" label-width="80px" size="mini">
            <el-form-item label="原密码" prop="originPwd">
               <el-input type="password" v-model="updatePwdForm.originPwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
               <el-input type="password" v-model="updatePwdForm.newPwd"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="resetPwd">
               <el-input type="password" v-model="updatePwdForm.resetPwd"></el-input>
            </el-form-item>
         </el-form>

         <span slot="footer" class="dialog-footer">
            <el-button @click="dialogPwdVisible = false">取 消</el-button>
            <el-button type="primary" @click="__updatePassword">确 定</el-button>
         </span>
      </el-dialog>
      <!-- 退出登录对话框 -->
      <el-dialog
         title="提示"
         :visible.sync="dialogLogoutVisible"
         width="30%"
         :before-close="closeLogout">
         <span>你确定要退出登录吗？</span>
         <span slot="footer" class="dialog-footer">
            <el-button @click="dialogLogoutVisible = false">取消</el-button>
            <el-button type="primary" @click="logout">确定</el-button>
         </span>
      </el-dialog>
      <Footer></Footer>
   </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { getUserInfoApi, updatePasswordApi } from '@/api/user.js'
import { mapMutations, mapState } from "vuex"


export default {
   data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.updatePwdForm.originPwd !== '') {
            this.$refs.updatePwdForm.validateField('originPwd');
          }
          callback();
        }
      };
      var validatePass1 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.updatePwdForm.newPwd !== '') {
            this.$refs.updatePwdForm.validateField('newPwd');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.updatePwdForm.newPwd) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
         dialogLogoutVisible: false,
         dialogPwdVisible: false,
         updatePwdForm: {
            originPwd: "",
            newPwd: "",
            resetPwd: ""
         },
         pwdRules: {
            originPwd: [
               { validator: validatePass, trigger: 'blur' }
            ],
            newPwd: [
               { validator: validatePass1, trigger: 'blur' }
            ],
            resetPwd: [
               { validator: validatePass2, trigger: 'blur' }
            ]
         }
      }
   },
   components: {
      Header,
      Footer
   },
   computed: {
      ...mapState({
         user: state => state.userInfo.userInfo
      })
   },
   created() {

   },
   methods: {
      async __updatePassword() {
         this.dialogPwdVisible = false;
         const res = await updatePasswordApi(this.updatePwdForm);
         if (!res) return;
         this.$message({message: '密码修改成功，请重新登录', type: 'success'});
         this.logout()
         console.log("修改Miami：", this.updatePwdForm);
      },
      closePwd() {
         this.dialogPwdVisible = false;
      },
      logout() {
         this.dialogLogoutVisible = false
         localStorage.removeItem("token")
         localStorage.removeItem("userInfo")
         this.$router.push("/login")
      },
      closeLogout() {
         this.dialogLogoutVisible = false
      },
      goToOrder() {
         this.$router.push("/order")
      }
   }
}
</script>

<style lang="less" scoped>

dd a {
   cursor: pointer;
}
.bb {
   margin-bottom: 10px !important;
}

#zhu {
	margin-top:10px;
	width: 1020px;
	background: #fff;
	float: left;
	box-shadow: 0px 0px 5px #ccc;
	margin-bottom: 10px;
	position: relative;

   p {
      font-family: "microsoft yahei";
      font-size: 16px;
      font-weight: bold;
      color: #666;
      padding: 7px 20px;
      border-bottom: 1px solid #efefef;

      span {
         float: right;
         font-size: 14px;
         padding: 3px 10px;
         border-radius: 3px;
      }
   }

   #shop {
      background: #fff;
      // position: absolute;
      top: 36px;
      width: 1020px;
      height: 278px;
   }

   #tishi2 {
      width: 350px;
      height: 100px;
      margin: 0px auto;
      margin-top: 150px;

      img {
         float: left;
      }

      p {
         float: left;
         border: 0px;
      }

      span {
         float:left;
         padding: 5px 10px;
         background: #ff3366;
         color: #fff;
         font-size: 14px;
         border-radius: 3px;
         margin: 8px 0px 0px 18px;
         cursor: pointer;
      }
   }
}

#right {
	width: 1020px;
	float: left;
	border-radius: 3px;
	margin-top: 10px;

   .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 150px;
      background: #fff;
      box-shadow: 0px 0px 5px #ccc;
      float: left;

      .set {
         color: #666;
         padding: 10px 20px;
         margin-right: 80px;
         border-radius: 20px;
         border: 1px solid #666;
      }

      &:nth-child(1) {
         margin-right: 10px;
      }

      &:nth-child(2) {
         dl {
            font-size: 14px;
            width: 220px;
            overflow: hidden;
            margin-top: 28px;
            margin-left: 20px;
            float: left;

            &:nth-child(2) a{
               padding: 3px 5px;
               color: #fff;
               background: #39bf3e;
               border-radius: 3px;
               margin-left: 3px;
            }
         }
      }

      dl {
         font-size: 14px;
         width: 500px;
         overflow: hidden;
         // margin-top: 28px;

         dt {
            float: left;
            margin-right: 20px;
            margin-left: 20px;
         }

         dd {
            line-height: 30px;

            a {
               text-decoration: none;
               margin-right: 10px;
               color: #2272c8;
            }
         }
      }
   }
}

#left {
   width: 158px;
   overflow: hidden;
   float: left;
   margin-right: 10px;
   background: #fff;
   box-shadow: 0px 0px 5px #ccc;
   padding-top: 25px;
   padding-bottom: 25px;
   margin-top: 10px;
   margin-left: 5px;

   dl {
      font-size: 14px;
      margin-left: 25px;
      line-height: 23px;

      i {
         margin-right: 4px;
      }

      span {
         color: red;
      }

      dt {
         font-weight: bold;
         margin-bottom: 5px;
         cursor: pointer;

         img {
            margin-right: 5px;
         }
      }

      dd {
         margin-left: 16px;
         font-family: Simsun;
         font-size: 12px;

         a {
            text-decoration: none;
            color: black;
         }
      }
   }
}

.home {
   width: 100%;

   .home-content {
      width: 100%;
      margin: 0 auto;
      background: #f5f5f5;

      #body {
         width: 1200px;
         overflow: hidden;
         margin: 0px auto;
         margin-bottom: 70px;
         padding-bottom: 30px;
      }
   }
}</style>