import Vue from 'vue'
// 清除默认样式 放在前面
import "reset-css"
import '@/assets/icons/index'
import '@/utils/filters'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'

Vue.prototype.$store = store
// 挂载一个自定义属性$http
Vue.prototype.$http = axios

// import $ from 'jquery';
// import 'jquery-zoom';

// 引用element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; // 使用
Vue.use(Element)

// 引入DataV
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

Vue.config.productionTip = false

// Vue.prototype.$ = $;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
