<template>
   <div class="login">
      <div class="logo-content" @click="goTo">
         <img src="../../assets/img/hng.png" style="width: 190px;" alt="">
      </div>
      <div class="register">
         <div class="login-container">
            <h1>惠农购</h1>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
               <el-form-item label="手机号" prop="phone">
                  <el-input type="text" v-model="ruleForm.phone" autocomplete="off"></el-input>
               </el-form-item>
               <el-form-item :label="'密\xa0\xa0\xa0\xa0码'" prop="password">
                  <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
               </el-form-item>
               <el-form-item label="验证码" prop="captchacode">
                  <div class="captcha-box">
                     <el-input v-model="ruleForm.captchacode"></el-input>
                     <img :src="captchaSrc" @click="getCaptchacode" />
                  </div>
               </el-form-item>
               <el-form-item id="login-btn-box" style="margin-bottom: 14px;">
                  <el-button class="login-btn" type="primary" @click="submitForm('ruleForm')">提交</el-button>
               </el-form-item>
               <div class="option">
                  <div class="tt"><router-link to="/register">注册账号</router-link></div>
                  <div class="tt"><router-link to="/login">忘记密码</router-link></div>
               </div>
            </el-form>
         </div>
      </div>
      <Footer></Footer>
   </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { validateUsername } from '@/utils/validate'
import { getCaptchacodeApi, loginApi } from '@/api/user.js'
// import { getCaptchacodeApi, loginApi } from '@/api/login'
import { mapActions } from 'vuex'

export default {
   data() {
      return {
         ruleForm: {
            phone: "",
            password: "",
            captchacode: ""
         },
         rules: {
            phone: [
               {
                  required: true, // 必填项
                  message: "手机号不能为空！", // 提示语
                  trigger: "blur"
               },
               // 自定义校验的写法
               { validator: validateUsername, trigger: 'blur' }
            ],
            password: [
               {
                  required: true, // 必填项
                  message: "密码不能为空！", // 提示语
                  trigger: "blur"
               }
            ],
            captchacode: [
               {
                  required: true, // 必填项
                  message: "验证码不能为空！", // 提示语
                  trigger: "blur"
               }
            ],
         },
         // 图片验证码路径
         captchaSrc: ""
      }
   },
   components: {
      Footer
   },
   created() {
      // 验证码请求
      this.getCaptchacode()
   },
   methods: {
      ...mapActions({
         asyncChangeUserInfo: "userInfo/asyncChangeUserInfo"
      }),
      goTo() {
         this.$router.push("/")
      },
      async getCaptchacode() {
         const res = await getCaptchacodeApi();
         const img = btoa(new Uint8Array(res).reduce((res, byte) => res + String.fromCharCode(byte),""));
         this.captchaSrc = "data:image/png;base64," + img;
      },
      async login() {
         const data = {
            phone: this.ruleForm.phone,
            password: this.ruleForm.password,
            code: this.ruleForm.captchacode,
         }

         const res = await loginApi(data);
         
         if (!res) return;
         // 提示用户登录成功
         this.$message({message: '登陆成功', type: 'success'});
         // 保存token
         localStorage.setItem("token", res.data.token)
         // 跳转首页
         this.$router.push("/")
         // 获取用户信息
         this.asyncChangeUserInfo()
         // console.log(localStorage.getItem("userInfo"));
      },
      submitForm(formName) {
         this.$refs[formName].validate((valid) => {
            if(valid) {
               // 校验通过，走这里的代码，发起登录请求
               this.login()
            }else {
               // 校验错误
               this.$message({
                  message: '请输入正确的信息后再进行提交！',
                  type: 'warning'
               });
               console.log('error submit!')
               return false;
            }
         })
      },
      
   }
}
</script>

<style lang="less" scoped>
   .option {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;
      font-size: 12px;

      .tt {
         cursor: pointer;

         &:hover a {
            color: #38bf43!important;
         }
      }
   }

   .logo-content {
      width: 1190px;
      height: 97px;
      margin: 0px auto;
      box-sizing: border-box;
   }
   .login {
      width: 100%;
      height: 100%;

      .register {
         position: relative;
         width: 100%;
         height: 600px;
         background-image: url('@/assets/img/register-bg.jpg');
         background-size: cover;
         background-attachment: fixed;
      }

      .login-container {
         position: absolute;
         width: 400px;
         top: 100px;
         right: 300px;
         padding-top: 20px;
         padding-right: 40px;
         padding-bottom: 30px;
         border-radius: 6px;
         background-color: #fff;
         box-shadow: 0 0 7px 3px rgba(0, 0, 0, .1);

         h1 {
            font-size: 20px;
            text-align: center;
            margin-bottom: 20px;
            padding-left: 40px;
         }

         .captcha-box {
            display: flex;

            img {
               width: 100px;
               height: 40px;
               margin-left: 20px;
            }
         }

         .login-btn {
            width: 100%;
         }

         // 样式覆盖的写法
         ::v-deep #login-btn-box .el-form-item__content {
            margin-left: 40px!important;
         }
      }
   }
</style>