import http from '@/utils/http'

// 查询购物车列表
export const getCartListApi = () => http ({
   url: '/cart/list',
   method: 'get'
})

// 添加商品到购物车
export const addCartApi = (data) => http ({
   url: '/cart/add',
   method: 'post',
   data: data
})

// 修改购物车
export const updateCartApi = (data) => http ({
   url: '/cart/update',
   method: 'post',
   data: data
})

// 删除购物车
export const delCartApi = (data) => http ({
   url: '/cart/del',
   method: 'get',
   params: data
})