<template>
   <div>
      <img src="@/assets/img/404.png" />
      <p>找不到页面！正在为您跳转到首页...{{ pnts }}</p>
   </div>
</template>

<script>
export default {
   name: "",
   data() {
      return {
         pnts: ""
      }
   },
   created() {
      let t = setInterval(() => {
         this.pnts += ".."
         if (this.pnts === "..........") {
            clearInterval(t)
         }
      }, 300)
      setTimeout(() => {
         this.$router.push("/")
      }, 2000)
   }
}
</script>

<style lang="less" scoped>
   img {
      display: block;
      width: 300px;
      height: 300px;
      margin: 0px auto;
   }

   p {
      color: #51b4f2;
      text-align: center;
      margin-top: 20px;
   }
</style>