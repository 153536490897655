<template>
   <div class="pay">
      <!-- 顶部logo -->
      <div class="logo">
         <div id="toubu">
            <router-link to="/"><img src="../../assets/img/hng.png" width="190" /></router-link>
         </div>
      </div>
      <div class="pay-content">
         <!-- 地址 -->
         <div id="shouhuo">
            <h4 style="margin-bottom: 7px; font-weight: 700;">收货信息</h4>
            <div class="box" style="display: flex; flex-wrap: wrap; justify-content: flex-start;">
               <div class="addressinfo" v-if="addressList" @click="curAddressIndex = index" name="addre" v-for="(item, index) in addressList"
                  :key="index">
                  <img :src="addressImg[index == curAddressIndex ? 0 : 1]" class="img">
                  <!-- <img v-else src="../../assets/img/default.png" class="img"> -->
                  <div class="wenzi">
                     <p>{{ item.realName }}&nbsp;&nbsp;{{ item.phone }}<span class="lable">{{ item.label }}</span><span
                           class="default" v-if="item.isDefault">默认</span></p>
                     <span class="add">
                        {{ item.province }} {{ item.city }} {{ item.area }}
                        {{ item.street }}
                     </span>
                     <a @click="handleOpenEditDialog(item)">修改</a>
                     <a @click="openDelAddress(item.id)" class="shanchu">删除</a>
                  </div>
               </div>
               <div id="addressinfo" @click="handleOpenAddDialog">
                  <img alt="" src="../../assets/img/addaddress.png">
               </div>
            </div>

         </div>
         <!-- 支付方式 -->
         <div id="zhifutype">
            <h4 style="margin-bottom: 7px; margin-top: 45px; font-weight: 700;">支付方式</h4>
            <span class="zhifu"><img src="../../assets/img/alipay.jpg" style="width: 80px;" alt=""></span>
         </div>
         <!-- 商品信息 -->
         <div id="shopinfo">
            <h4 style="margin-bottom: 7px; font-weight: 700;">商品及服务信息</h4>
            <table id="shoptable" border="0px" cellpadding="0px" cellspacing="0px">
               <thead>
                  <td align="center" class="title"><span style="margin-left: 30px;">惠农购【已认证】</span></td>
                  <td class="fuwu">服务信息</td>
                  <td class="info">单价</td>
                  <td class="info">数量</td>
                  <td class="info">小计</td>
               </thead>
               <tbody 
                  v-for="(item, index) in selectedItems"
                  :key="index"
               >
                  <td style="border-right: 1px #eee solid;border-top: 1px #eee solid;">
                     <div class="xinxi" style="display: flex; justify-content: space-between;">
                        <img style="display: block; margin-right: 24px;" :src="item.coverUrl" width="60px" height="60px">
                        <div class="kk">
                           <span class="biaoti">{{ truncateString(item.name) }}</span>
                           <p style="color: #999">规格：{{ item.specName }}</p>
                           <p style="color: #999">单价：{{ item.price }}</p>
                           <p style="color: #999">七天无理由退货</p>
                        </div>
                     </div>
                  </td>
                  <td align="center" style="border-right:1px #eee solid;">完成后尽快为您发货</td>
                  <td align="center">￥{{ item.price }}</td>
                  <td align="center">{{ item.quantity }}</td>
                  <td align="center" class="money">￥{{ parseFloat((item.price * item.quantity).toFixed(2)) }}</td>
               </tbody>
               <tfoot>
                  <tr>
                     <td colspan="2" class="beizhu-box">
                        备注：
                        <input type="text" size="110" class="beizhu" name="beizhu" onkeyup="state(this)">
                     </td>
                     <td align="center">总计</td>
                     <td></td>
                     <td align="center" class="money">￥{{ countPrice }}</td>
                  </tr>
               </tfoot>
            </table>
         </div>
         <!-- 发票信息 -->
         <div id="fapiao">
            <h4 style="margin-bottom: 7px; font-weight: 700;">发票信息</h4>
            <div id="xx">
               <p style="width: 300px;">发票类型：<span>纸质普通发票</span></p>
               <p>发票抬头：<span id="names" v-if="addressList">{{ addressList[curAddressIndex].realName }}</span></p>
            </div>
         </div>
         <!-- 结算 -->
         <div id="jiesuan">
            <h4 style="margin-bottom: 7px; font-weight: 700;">结算信息</h4>
            <div id="shops">
               <div id="xins">
                  <ul>
                     <li class="money">￥{{ countPrice }}</li>
                     <li>￥0.00</li>
                     <li>￥0.00</li>
                     <li>￥0.00</li>
                  </ul>
                  <ul>
                     <li><span class="count">2</span>件商品 总计：</li>
                     <li>运费：</li>
                     <li>优惠：</li>
                     <li>优惠券/卡：</li>
                  </ul>
                  <span id="daifu"><input type="checkbox">找人代付</span>
               </div>
            </div>
         </div>
      </div>
      <!-- 添加收货地址 -->
      <el-dialog :title="(selectedEditAddress == null ? '添加': '修改') + '地址'" :visible.sync="dialogAddressVisible" width="30%" :before-close="handleClose">
         <el-form ref="addressForm" :model="addressForm" label-width="80px" size="small">
            <el-form-item label="收货人">
               <el-input v-model="addressForm.realName"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
               <el-input v-model="addressForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="所在地址">
               <el-cascader size="large" 
                  :options="pcaTextArr"
                  v-model="selectedOptions" 
               >
               </el-cascader>
            </el-form-item>
            <el-form-item label="详细地址">
               <el-input type="textarea" v-model="addressForm.street"></el-input>
            </el-form-item>
            <el-form-item label="默认地址">
               <el-switch v-model="addressForm.isDefault"></el-switch>
            </el-form-item>
            <el-form-item label="地址标签">
               <el-radio-group v-model="addressForm.label" size="medium">
                  <el-radio border label="学校"></el-radio>
                  <el-radio border label="公司"></el-radio>
                  <el-radio border label="家"></el-radio>
               </el-radio-group>
            </el-form-item>
         </el-form>


         <span slot="footer" class="dialog-footer">
            <el-button @click="dialogAddressVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleSaveAddress">确 定</el-button>
         </span>
      </el-dialog>
      <!-- 支付对话框 -->
      <el-dialog
         title="提示"
         :visible.sync="dialogPayVisible"
         width="30%"
         :before-close="handlePayClose">
         <span>您已完成支付？</span>
         <span slot="footer" class="dialog-footer">
            <el-button @click="handlePayClose">未支付</el-button>
            <el-button type="primary" @click="handlePayClose">已支付</el-button>
         </span>
      </el-dialog>
      <footer>
         <div id="tijiao">
            <p class="jine">应付金额：<span id="price" class="money">￥{{ countPrice }}</span></p>
            <p id="tongyao"><span id="lijian">立减</span>惠农支付立减5元 <a
                  style="text-decoration: none;color: #28e">点此领取</a></p>
            <p v-if="addressList" class="jine">收货信息：
               <span class="info" style="color:#000;" id="shouhuos">
                  {{ addressList[curAddressIndex].realName }} {{ formatPhoneNumber(addressList[curAddressIndex].phone) }} {{ addressList[curAddressIndex].province }}{{ addressList[curAddressIndex].city }}{{ addressList[curAddressIndex].area }}{{ addressList[curAddressIndex].street }}
               </span>
            </p>
            <p class="jine">支付方式：<span class="info" style="color:#000;" id="fukuanfangshi">支付宝</span></p>
            <p>
               <span id="ts">
                  <img alt="" src="../../assets/img/tips.png" width="16px" style="vertical-align: middle;">
                  提交订单后尽快支付，商品才不会被人抢走哦！
               </span><input @click="__saveOrders" type="button" id="submits" class="tjs" value="提交订单">
            </p>
         </div>
      </footer>
   </div>
</template>

<script>
import { pcaTextArr } from 'element-china-area-data'
import { payApi, saveOrdersApi } from '@/api/orders.js'
import { addAddressApi, delAddressApi, updateAddressApi, getAddressListApi } from "@/api/address.js"
// 引入工具类中的方法
import { generateOrderNumber } from '@/utils/orderUtils.js';

export default {
   props: ['selectedItems'],
   data() {
      return {
         dialogPayVisible: false,
         curAddressIndex: 0,
         addressList: [],
         addressImg: [
            require("../../assets/img/address.png"),
            require("../../assets/img/default.png"),
         ],
         pcaTextArr,
         selectedOptions: [], // 选中的省市区
         dialogAddressVisible: false, // 添加/修改地址的对话框
         selectedEditAddress: null,  // 选中的需要修改的地址
         addressForm: {
            realName: '',
            phone: '',
            label: '',
            province: '',
            city: '',
            area: '',
            street: '',
            isDefault: false
         },
         originalAddressForm: {  // 初始空值
            realName: '',
            phone: '',
            label: '',
            province: '',
            city: '',
            area: '',
            street: '',
            isDefault: false
         },

      }
   },
   computed: {
      // 计算总价
      countPrice() {
         if (this.selectedItems) {
            let total = this.selectedItems.reduce((total, item) => {
               if (item.price) {
                  return total + item.price * item.quantity;
               }
               return total;
            }, 0);
            return parseFloat(total.toFixed(2));
         }
         return 0;
      },
   },
   created() {
      this.__getAddressList()
   },
   methods: {
      async __getAddressList() {
         const res = await getAddressListApi();
         if (!res) return;
         this.addressList = res.data;
         console.log(this.addressList);
      },
      async __delAddress(id) {
         const res = await delAddressApi({ id: id });
         if (!res) return 0;
         this.__getAddressList();
         return 1;
      },
      async __addAddress(address) {
         const res = await addAddressApi(address);
         if (!res) return;
         this.$message({message: '添加收货地址成功', type: 'success'});
         this.__getAddressList()
      },
      async __updateAddress(address) {
         const res = await updateAddressApi(address);
         if (!res) return;
         this.$message({message: '修改收货地址成功', type: 'success'});
         this.__getAddressList()
      },
      // 用户下单
      async __saveOrders() {
         const { realName, phone, province, city, area, street } = this.addressList[this.curAddressIndex];
         let outTradeNo = generateOrderNumber();
         // 传给后端的数据
         const orderData = {
            addressFull: `${province}${city}${area}${street}`,
            totalAmount: 0,
            receiverInfo: `${realName} ${phone} `,
            outTradeNo: outTradeNo,
            storeId: 0,
            productList: this.selectedItems
         }
         // 处理后的订单数据格式
         let processedOrders = this.splitOrderByStore(orderData); // 调用方法，遍历商品列表，将商品按店铺ID分组
         // 调用后端下单的接口
         const res = await saveOrdersApi({orderData: processedOrders});
         if (!res) return;
         // 调用支付宝支付的方法
         this.__pay(outTradeNo);
      },
      // 跳转支付宝沙箱界面
      async __pay(outTradeNo) {
         // 支付宝沙箱需要的参数
         let data = {
            traceNo: outTradeNo,
            totalAmount: this.countPrice,
            subject: this.selectedItems[0].name
         }
         const alipayURL = process.env.VUE_APP_ALIPAY_URL; // 后端支付宝支付的接口
         // 浏览器打开支付界面
         window.open(`${alipayURL}?subject=${data.subject}&traceNo=${data.traceNo}&totalAmount=${data.totalAmount}`);
         this.dialogPayVisible = true
      },
      goToOrder() {
         this.$router.push("/order")
      },
      splitOrderByStore(orderData) {
         const storeOrders = {};

         // 遍历商品列表，将商品按店铺ID分组
         orderData.productList.forEach(product => {
            const storeId = product.storeId;

            // 初始化每个店铺的订单信息
            if (!storeOrders[storeId]) {
                  storeOrders[storeId] = {
                     addressFull: orderData.addressFull,
                     totalAmount: 0, // 总金额从商品列表中计算
                     receiverInfo: orderData.receiverInfo,
                     outTradeNo: orderData.outTradeNo,
                     storeId: storeId,
                     productList: []
                  };
            }

            // 添加商品到相应店铺的商品列表
            storeOrders[storeId].productList.push(product);

            // 计算每个店铺的总金额
            storeOrders[storeId].totalAmount += product.price * product.quantity;

            // 设置店铺id
            storeOrders[storeId].storeId = storeId;
         });

         // 转换对象为数组返回
         return Object.values(storeOrders);
      },
      queryOrderState() {
         // 查询订单状态
         this.dialogPayVisible = false
      },
      handlePayClose(done) {
         // this.queryOrderState()
         this.dialogPayVisible = false
         this.goToOrder()
      },
      handleClose(done) {
         done(); // 关闭对话框
      },
      formatPhoneNumber(number) {
         if (/^\d{11}$/.test(number)) {
            // 使用正则表达式将中间四位替换为星号
            return number.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
         }
         return number;
      },
      truncateString(str, length = 23) {
         // 如果字符串长度小于或等于长度限制，直接返回原字符串
         if (str.length <= length) {
            return str;
         }
         // 截取指定长度的字符串，并在末尾添加省略号
         const truncated = str.slice(0, length - 3) + '...';
         return truncated;
      },
      handleOpenAddDialog() { // 添加地址
         // 打开添加地址对话框，表单重置为初始值
         this.addressForm = { ...this.originalAddressForm };
         this.selectedEditAddress = null;
         this.selectedOptions = []
         this.dialogAddressVisible = true;
      },
      handleOpenEditDialog(address) { // 修改地址
         // console.log('address: ', address);
         // 打开修改地址对话框，将表单数据填充为选中的地址
         this.selectedOptions[0] = address.province
         this.selectedOptions[1] = address.city
         this.selectedOptions[2] = address.area
         this.addressForm = { ...address };
         this.selectedEditAddress = address;
         this.addressForm.isDefault = !!address.isDefault
         this.dialogAddressVisible = true;
         // console.log('selectedOptions: ', this.selectedOptions);
      },
      handleSaveAddress() { // 对话框里的提交按钮
         if (this.selectedEditAddress) {
            // 修改地址
            Object.assign(this.selectedEditAddress, this.addressForm);
            this.selectedEditAddress.isDefault = this.selectedEditAddress ? 1 : 0
            this.__updateAddress(this.selectedEditAddress);
            // console.log("修改地址：", this.selectedEditAddress);
         } else {
            // 添加地址
            this.addressForm.province = this.selectedOptions[0]
            this.addressForm.city = this.selectedOptions[1]
            this.addressForm.area = this.selectedOptions[2]
            this.addressForm.isDefault = this.addressForm.isDefault ? 1 : 0
            this.__addAddress(this.addressForm)
            console.log("添加地址：", this.addressForm);
            // this.addressList.push({ ...this.addressForm });
         }
         this.dialogAddressVisible = false;
      },
      openDelAddress(id) {
         this.$confirm('你确定要删除该地址吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let res = this.__delAddress(id)
            if (res == 1) {
               this.$message({
                  type: 'success',
                  message: '删除成功!'
               });
            }
         }).catch(() => {

         });
      }
   }
}
</script>

<style lang="less" scoped>
.money {
   color: red;
   font-weight: 700;
}

#tijiao {
   width: 1130px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 20px;
   padding-bottom: 100px;

   p {
      width: 1130px;
      display: inline-block;
      text-align: right;
      line-height: 30px;
   }

   .jine {
      font-size: 14px;
      color: #999;
   }

   #tongyao {
      font-size: 12px;
   }

   #lijian {
      display: inline-block;
      height: 18px;
      padding: 0 8px;
      margin-right: 5px;
      line-height: 18px;
      border: 1px solid #f60;
      color: #f60;
      font-weight: 700;
   }

   .jine {
      font-size: 14px;
      color: #999;
   }

   #ts {
      color: #ff3366;
      font-size: 14px;
      margin-right: 10px;
   }

   #submits {
      display: inline-block;
      padding: 5px 15px;
      color: #fff;
      background: #ff3366;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      border: 0px;
   }
}

#jiesuan {
   width: 1190px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 20px;

   #shops {
      width: 1188px;
      height: 190px;
      border: 1px #eee solid;

      #xins {
         width: 220px;
         height: 130px;
         float: right;
         margin-right: 30px;
         margin-top: 30px;

         #daifu {
            float: right;
            font-family: SimSun;
            font-size: 13px;
            margin-top: 20px;
         }

         ul {
            width: 108px;
            overflow: hidden;
            padding: 3px 0;
            color: #999;
            font-family: SimSun;
            font-size: 12px;
            list-style: none;
            line-height: 24px;
            float: left;

            &:nth-child(1) {
               float: right;
               color: #000;
               font-family: '微软雅黑';
               font-weight: 700;
               color: #000;
               font-size: 13px;
            }

            li {
               text-align: right;

               .count {
                  color: #ff3366;
               }
            }
         }
      }
   }
}

#fapiao {
   width: 1190px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 20px;

   #xx {
      width: 1188px;
      height: 58px;
      border: 1px #eee solid;

      p {
         font-size: 12px;
         line-height: 58px;
         margin-left: 30px;
         color: #999;
         float: left;
         display: block;

         span {
            color: #000;
         }
      }
   }
}

.beizhu-box {
   border-top: 1px #eee solid;
   border-right: 1px #eee solid;
   // text-align: right;

   .beizhu {
      border: 1px #ccc solid;
      outline: none;
      font-size: 12px;
      height: 20px;
   }
}

#shopinfo {
   width: 1190px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 45px;
}

.biaoti {
   height: 15px;
   overflow: hidden;
   display: block;
}

.xinxi {
   // width: 285px;
   overflow: hidden;
   margin: 13px 0 30px 10px;
   padding-right: 18px;

   p {
      line-height: 19px;
   }
}

thead {
   background: #f8f8f8;
   border-bottom: 1px red solid;

   .title {
      width: 396px;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      margin-left: 20px;
      border-bottom: 1px #eee solid;
   }

   .fuwu {
      width: 396px;
      height: 40px;
      color: #666;
      text-align: center;
      border-bottom: 1px #eee solid;
   }

   .info {
      width: 132px;
      height: 32px;
      text-align: center;
      color: #666;
      border-bottom: 1px #eee solid;
   }

   tbody {

      td {
         vertical-align: none;

         &:nth-child(1) img {
            float: left;
            margin-right: 10px;
         }
      }
   }

   tfoot td:nth-child(1) {
      width: 789px;
      height: 60px;
      border-right: 1px #eee solid;
   }
}

#shoptable {
   width: 1190px;
   overflow: hidden;
   border: 1px #eee solid;
   margin-top: 20px;
   font-size: 12px;
}

#zhifutype {
   width: 1190px;
   height: 73px;
   margin: 0px auto;

   span {
      display: inline-block;
      padding: 12px 70px;
      border: 1px solid #1878fb;
      font-size: 14px;
      margin-top: 4px;
   }
}

.default {
   color: #bd8f4c;
   background-color: #ffe5bf;
   padding: 0 4px;
   margin-right: 5px;
   float: right;
}

.lable {
   border: 1px solid #ffe2c9;
   background-color: #fff6ef;
   color: #f60;
   padding: 0px 5px;
   float: right;
}

#shouhuo {
   width: 1190px;
   // overflow: hidden;
   margin-bottom: 30px;
   margin: 0 auto;

   #addressinfo {
      width: 285px;
      height: 112px;
      float: left;
      position: relative;
      cursor: pointer;
      margin-top: 16.5px;
   }

   .addressinfo {
      width: 285px;
      height: 112px;
      // float: left;
      position: relative;
      cursor: pointer;
      margin-right: 14px;
      margin-top: 16.5px;

      .shanchu {
         display: none;
      }

      &:hover .shanchu {
         display: inline-block;
      }

      .wenzi {
         width: 285px;
         height: 110px;
         position: absolute;
         top: 0px;

         p {
            width: 249px;
            font-size: 12px;
            display: inline-block;
            margin-left: 15px;
            border-bottom: 1px #ccc dashed;
            padding: 14px 0px 5px 0;
         }

         .add {
            display: block;
            clear: both;
            font-size: 12px;
            width: 249px;
            margin-left: 15px;
            height: 40px;
            margin-top: 5px;
         }

         a {
            font-size: 12px;
            text-decoration: none;
            color: #28e;
            float: right;
            margin-right: 25px;
         }
      }
   }
}

.logo {
   width: 100%;
   overflow: hidden;

   #toubu {
      width: 1190px;
      height: 100px;
      margin: 0px auto;
   }
}

.pay {
   width: 100%;

   .pay-conetnt {
      width: 1190px;
      margin: 0 auto;
   }
}
</style>