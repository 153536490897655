<template>
   <div class="cart">
      <Header :show="false" :navShow="false"></Header>
      <div class="cart-content">
         <!-- 购物车是空的 -->
         <div id="kongde" v-if="false">
            <div id="neirong">
               <img src="../../assets/img/gwc1.png" width="120px;">
               <span>您的购物车是空的，去商城看看吧。</span>
            </div>
         </div>
         <!-- 商品列表 -->
         <div id="zongde" v-else>
            <div id="caida">
               <p>
                  <input v-model="selectAll" type="checkbox" name="checks" @change="toggleSelectAll" checked="checked">全选
               </p>
               <ul>
                  <li>商品信息</li>
                  <li>规格</li>
                  <li>单价（元）</li>
                  <li>数量</li>
                  <li>小计（元）</li>
                  <li>操作</li>
               </ul>
            </div>
            <form id="form">
               <div class="shop" style="background-color: rgb(255, 251, 242);" v-for="(item, index) in cartList"
                  :key="index">
                  <p class="xuan">
                     <input v-model="item.checked" @change="handleCheckItemChange"  type="checkbox" class="check" name="check" checked="checked">
                  </p>
                  <img :src="item.coverUrl" alt="">
                  <span>{{ item.name }}</span>
                  <div class="guige">
                     <p>规格：{{ item.specName }}</p>
                  </div>
                  <div class="money">
                     <p name="yuanjiage">{{ item.price }}</p>
                  </div>
                  <div class="number">
                     <div class="input">
                        <input @click="item.quantity--" type="button" value="-" name="jian">
                        <input type="text" v-model="item.quantity" name="count">
                        <input @click="item.quantity++" type="button" value="+" name="jia">
                     </div>
                  </div>
                  <div class="xiaoji" style="font-weight: 700;">
                     <strong id="zongjia0" class="danjia" name="danjia">￥{{ parseFloat(item.price * item.quantity).toFixed(2) }}</strong>
                  </div>
                  <div class="caozuo">
                     <p @click="__delCart(item.id)">删除</p>
                     <p>移入收藏</p>
                  </div>
               </div>
               <!-- 下 -->
               <div id="state">
                  <a href="#">已选中<span class="shuliang">{{ selectedCount }}</span>件商品</a>
                  <div id="right">
                     <span id="js" style="cursor: pointer;background: #ff3366" @click="checkout">去结算</span>
                     <div id="jg">
                        <p>总价（含运费）<span id="jg2">￥{{ countPrice }}</span></p>
                        <p>运费（以结算页为准）：¥{{ countPrice }}</p>
                     </div>
                     <div class="xians"></div>
                  </div>
               </div>
            </form>
         </div>

         <div id="like">
            猜你喜欢
         </div>
         <div id="likes">
            <div id="width">
               <dl 
                  style="margin-left: 11px; cursor: pointer;"
                  v-for="(item, index) in topSellingList"
                  :key="index"
                  @click="goToProductDetail(item.id)"
               >
                  <dt><img :src="item.coverUrl" height="160" width="160" class="imgs" /></dt>
                  <dd>￥<span class="jg">{{ item.price }}</span><span class="gm"
                        style="color: #999;float: right;margin-right: 20px;margin-top: 5px">
                        <span style="color: black">{{ item.buyNum }}</span>人已购买
                     </span></dd>
                  <dd><span>自营</span>
                     <span style="color: #000; background-color: transparent;">
                        {{ truncateString(item.name) }}
                     </span>
                  </dd>
                  <dd class="jiarugouwuches" style="cursor: pointer;">加入购物车</dd>
               </dl>
            </div>
         </div>
      </div>
      <Footer></Footer>
   </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { getCartListApi, updateCartApi, delCartApi } from "@/api/cart.js"
import { getTopSellingListApi } from "@/api/product"

export default {
   data() {
      return {
         selectAll: false,
         cartList: [],
         topSellingList: []
      }
   },
   components: {
      Header,
      Footer
   },
   watch: {
      cartList: {
         handler(newList, oldList) {
            newList.forEach((item, index) => {
               if (item.quantity < 1) {
                  this.$message({ message: '数量不能小于1', type: 'warning' });
                  item.quantity = 1;
               }
            });
         },
         deep: true
      }
   },
   computed: {
      // 计算总价
      countPrice() {
         const total = this.cartList.reduce((total, item) => {
            if (item.checked) {
               return total + item.price * item.quantity;
            }
            return total;
         }, 0);
         return parseFloat(total.toFixed(2));
      },
      // 计算选中的商品数量
      selectedCount() {
         return this.cartList.reduce((count, item) => {
            return count + (item.checked ? 1 : 0);
         }, 0);
      },
      selectedItems() {
         return this.cartList.filter(item => item.checked);
      }
   },
   created() {
      this.__getCartList()
      this.__getTopSellingList()
   },
   methods: {
      async __getCartList() {
         const res = await getCartListApi();
         if (!res) return;
         // 给每个商品加上是否被选中的值
         res.data.forEach(item => {
            item.checked = false;
         });
         this.cartList = res.data;
         // console.log(this.cartList);
      },
      async __delCart(cartId) {
         const res = await delCartApi({ cartId: cartId });
         if (!res) return;
         this.$message({ message: '删除成功', type: 'success' });
         this.__getCartList()
      },
      async __getTopSellingList() {
         const res = await getTopSellingListApi();
         if (!res) return;
         this.topSellingList = res.data
      },
      toggleSelectAll() {
         const newStatus = this.selectAll;
         // 将所有子复选框与全选状态同步
         this.cartList.forEach(item => {
            item.checked = newStatus;
         });
      },
      goToProductDetail(productId) {
         this.$router.push({ path: '/product', query: {productId: productId} });
      },
      truncateString(str, length = 12) {
         // 如果字符串长度小于或等于长度限制，直接返回原字符串
         if (str.length <= length) {
            return str;
         }
         // 截取指定长度的字符串，并在末尾添加省略号
         const truncated = str.slice(0, length - 3) + '...';
         return truncated;
      },
      handleCheckItemChange() {
         // 检查如果有一个子复选框未选中，全选复选框取消选中
         if (this.cartList.some(item => !item.checked)) {
            this.selectAll = false;
         } else {
         // 如果所有子复选框都选中，全选复选框选中
            this.selectAll = true;
         }
      },
      checkout() {
         if (this.selectedItems == 0) {
            this.$message({ message: '请选择商品', type: 'warning' });
            return;
         }
         this.$router.push({ 
            name: 'pay', 
            params: { selectedItems: this.selectedItems } 
         });
         console.log(this.selectedItems);
      }
      
   }
}
</script>

<style lang="less" scoped>
#width {
   width: 2000px;
   overflow: hidden;
}

#like {
   width: 142px;
   overflow: hidden;
   margin-top: 35px;
   border: 1px #ddd solid;
   border-bottom: 0px;
   font-weight: 700;
   font-size: 13px;
   text-align: center;
   line-height: 41px;
   background-color: #fff;
   position: relative;
   z-index: 1000;

}

#likes {
   width: 1188px;
   overflow: hidden;
   border: 1px #ddd solid;
   margin-top: -1px;
   z-index: -1;

   dl {
      margin: 0px;
      padding: 0px;
      width: 223px;
      height: 302px;
      float: left;
      margin: 10px 5px;
      border: 1px #fff solid;
      transition: all 0.5s ease-in-out;

      &:hover {
         border: 1px #f60 solid;
         transition: all 0.2s ease-in-out;
      }

      dt {
         margin: 0px;
         padding: 0px;
         margin-top: 15px;
         margin-left: 29px;
         margin-bottom: 5px;
      }

      dd {
         margin-left: 17px;
         margin-right: 12px;
         font-size: 12px;

         &:nth-of-type(1) {
            color: #f60;

            .jg {
               font-size: 18px;
               color: #f60;
            }
         }

         &:nth-of-type(2) {
            margin-top: 5px;
            line-height: 20px;

            span {
               padding: 2px 3px;
               background-color: #f60;
               color: #fff;
               border-radius: 2px;
               margin-right: 5px;
            }
         }

         &:nth-of-type(3) {
            display: inline-block;
            padding: 5px 50px;
            border: 1px #ddd solid;
            margin-top: 8px;
            transition: all 0.5s ease-in-out;

            :hover {
               color: orangered;
               border: 1px #f60 solid;
               cursor: pointer;
               transition: all 0.5s ease-in-out;
            }

            img {
               vertical-align: middle;
               margin-right: 5px;
            }
         }
      }
   }
}

#js {
   display: inline-block;
   padding: 20px 30px;
   background-color: #f60;
   color: #fff;
   font-weight: bold;
   float: right;
   margin-top: -1px;
}

#jg2 {
   font-size: 21px;
   color: #f60;
   font-weight: 400;
}

.xians {
   height: 40px;
   border-left: 1px #ddd solid;
   margin-top: 10px;
   float: right;
}

#state {
   width: 1188px;
   height: 59px;
   border: 1px #ddd solid;
   margin-top: 28px;

   a {
      font-size: 12px;
      color: #666;
      text-decoration: none;
      line-height: 59px;
      margin-left: 15px;
      float: left;

      span {
         color: #f60;
         font-weight: bold;
         margin: 0px 1px;
      }
   }

   #right {
      width: 500px;
      height: 59px;
      float: right;

      #jg {
         width: 200px;
         height: 59px;
         float: right;
         margin-left: 10px;

         p {
            display: inline-block;
            font-size: 12px;

            &:nth-of-type(1) {
               margin-top: 3px;
               margin-bottom: -5px;
            }

            &:nth-of-type(2) {
               color: #999;
            }
         }
      }
   }
}

.xiaoji {
   font-size: 13px;

   strong {
      margin-left: 60px;
      line-height: 110px;
   }
}

.input {
   width: 94px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 40px;
   text-align: center;

   [name=jian] {
      height: 25px;
      width: 25px;
      float: left;
   }

   [type=text] {
      width: 40px;
      height: 21px;
      margin: 0px;
      padding: 0px;
      float: left;
      text-align: center;
   }

   [name=jia] {
      height: 25px;
      width: 25px;
      float: left;
   }
}

.shop {
   width: 1188px;
   height: 110px;
   margin-top: 20px;
   border: 1px #ddd solid;

   .xuan {
      line-height: 110px;
      margin-left: 20px;
      margin-right: 20px;
      float: left;
   }

   img {
      width: 80px;
      height: 80px;
      margin-top: 15px;
      float: left;
   }

   span {
      display: inline-block;
      width: 248px;
      font-size: 12.3px;
      margin-top: 25px;
      margin-left: 15px;
      float: left;
   }

   .guige,
   .money,
   .number,
   .xiaoji,
   .caozuo {
      width: 150px;
      height: 10px;
      float: left;
      margin: 1px;
   }

   .caozuo {
      p {
         font-size: 13px;
         margin-left: 55px;

         &:nth-of-type(1) {
            margin-left: 68px;
            margin-top: 35px;
            margin-bottom: 5px;
         }

         &:hover {
            color: #FF3366;
            text-decoration: underline;
            cursor: pointer;
         }
      }
   }

   .money p {
      color: black;
      font-size: 13px;
      margin-left: 28px;
      line-height: 110px;
   }

   .guige {
      p {
         color: #666;
         font-size: 12.3px;
         margin-left: 15px;

         &:nth-of-type(1) {
            margin-top: 35px;
            // line-height: 110px;
         }
      }
   }
}

#caida {
   width: 1188px;
   height: 30px;
   border: 1px #ddd solid;
   border-top: 3px #ddd solid;
   font-size: 12.3px;

   p {
      line-height: 30px;
      margin-left: 10px;
      float: left;
   }

   ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      float: left;
      line-height: 30px;
      margin-left: 140px;

      li {
         float: left;
         margin: 0px 63px;

         &:nth-of-type(6) {
            margin-left: 35px;
         }
      }
   }
}

#kongde {
   width: 1188px;
   height: 400px;
   margin: 0px auto;

   #neirong {
      width: 400px;
      overflow: hidden;
      line-height: 300px;
      margin: 0px auto;

   }
}

.cart {
   width: 100%;

   .cart-content {
      width: 1190px;
      margin: 0 auto;
   }
}</style>