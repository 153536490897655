import http from '@/utils/http'

// 获取导航栏列表
export const searchByCategoryApi = (data) => http ({
   url: '/product/search-category',
   method: 'get',
   params: data
})

// 模糊搜索商品
export const searchByKeywordApi = (data) => http ({
   url: '/product/search',
   method: 'get',
   params: data
})

// 查看商品详情
export const getProductDetailApi = (data) => http ({
   url: '/product/detail',
   method: 'get',
   params: data
})

// 查看商品评论
export const getCommentListApi = (data) => http ({
   url: '/product/comment-list',
   method: 'get',
   params: data
})

// 查找最新上架的商品列表
export const getNewProductListApi = () => http ({
   url: '/product/top-products',
   method: 'get',
})

// 查找畅销商品
export const getTopSellingListApi = () => http ({
   url: '/product/top-selling-products',
   method: 'get',
})
