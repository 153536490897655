<template>
   <div class="order">
      <Header :homeShow="true"></Header>
      <div class="order-content">
         <div id="body">
            <!-- 左边菜单 -->
            <div id="left">
               <dl>
                  <dt><img alt="" src="../../assets/img/xq.gif">我的订单</dt>
                  <dd v-for="(item, index) in stateName.slice(1, 5)" :key="index">
                     <a 
                        :style="{ color: orderStatus === (index + 1) ? '#39bf3e' : 'inherit' }" 
                        @click="orderStatus = index + 1">{{ item }}
                     </a>
                  </dd>
               </dl>
            </div>
            <!-- 中间主题 -->
            <div id="zhu">
               <p>{{ stateName[orderStatus] }}</p>
               <table 
                  v-if="!!orderDetail"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  v-for="(order, index) in orderDetail"
                  :key="index"
               >
                  <thead>
                     <tr>
                        <td colspan="5">
                           <span class="title">订单编号：</span><span>{{ order.outTradeNo }}</span>&nbsp;
                           <span class="title">下单时间：</span><span>{{ order.createTime }}</span>&nbsp;
                           <span class="title">实付总金额：</span><span>{{ order.totalAmount }}</span>&nbsp;
                           <span class="title">支付方式：</span><span>支付宝</span>&nbsp;
                           <span class="title">支付宝交易凭证号：</span><span>{{ order.tradeNo }}</span>&nbsp;
                        </td>
                     </tr>
                     <tr>
                        <td colspan="5">
                           <span class="title">收货信息：</span><span>{{ order.receiverInfo }}{{ order.addressFull }}</span>&nbsp;
                        </td>
                     </tr>
                     <tr class="shop-hd" align="center">
                        <td>商品信息</td>
                        <td>商品规格</td>
                        <td>购买数量</td>
                        <td>实付金额</td>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(item, idx) in order.orderItems" :key="idx">
                        <td style="border-right: 1px #e5e5e5 solid;border-bottom:1px #e5e5e5 solid; width: 400px">
                           <div class="shop">
                              <img :src="item.coverUrl" height="80">
                              <a href="#">{{ item.productName }}</a>
                           </div>
                        </td>
                        <td class="kk" align="middle">{{ item.productSpec }}</td>
                        <td class="kk" align="middle">{{ item.productNum }}</td>
                        <td class="mm" align="middle">￥{{ item.productPrice }}</td>
                     </tr>
                  </tbody>
                  <tfoot>
                     <tr>
                        <td colspan="5" class="caozuo" align="center">
                           <a v-if="orderStatus == 1">马上支付</a>
                           <a v-if="orderStatus == 1">修改订单</a>
                           <a v-if="orderStatus == 1 || orderStatus == 2">取消订单</a>
                           <a v-if="orderStatus == 3" @click="__receiveGoods(order.id)">确认收货</a>
                           <a v-if="orderStatus == 4" @click="openCommentForm(order.orderItems)">填写评价</a>
                        </td>
                     </tr>
                  </tfoot>
               </table>
               <!-- <el-empty v-if="isEmpty" description="暂无订单"></el-empty> -->
            </div>
         </div>
         <el-dialog title="填写评价" :visible.sync="openComment" width="40%">
            <el-form :model="commentForm"  label-width="80px">
               <el-form-item label="评分">
                  <el-rate
                     v-model="commentForm.rating"
                     show-text
                  >
                  </el-rate>
               </el-form-item>
               <el-form-item label="评价内容" >
                  <el-input type="textarea" v-model="commentForm.content"></el-input>
               </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
               <el-button @click="openComment = false">取 消</el-button>
               <el-button type="primary" @click="__addComment">确 定</el-button>
            </div>
         </el-dialog>
      </div>
      <!-- <Footer></Footer> -->
   </div>
</template>

<script>
import { getOrderInfoApi, receiveGoodsApi } from "@/api/orders"
import { addCommentApi } from "@/api/comment"
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
   data() {
      return {
         orderStatus: 1,
         stateName: ["全部订单", "待付款", "待发货", "待收货", "待评价"],
         stateList: [
            {name: "待付款", nextState: "待支付", btn: "马上支付"},
            {name: "待收货", nextState: "正在配送", btn: "确认收货"},
            {name: "已完成", nextState: "交易成功", btn: "删除记录"}
         ],
         orderDetail: [],
         isEmpty: true,
         openComment: false,
         commentForm: {
            rating: 0,
            content: ""
         },
         orderItemsData: null
      }
   },
   components: {
      Header,
      Footer
   },
   created() {
      this.__getOrderInfo()
   },
   watch: {
      orderStatus(newStatus) {
         this.orderStatus = newStatus
         this.__getOrderInfo();
      },
      orderDetail(newOrderDetail) {
         this.isEmpty = (newOrderDetail.length == 0 ? false : true)
      }
   },
   methods: {
      async __getOrderInfo() {
         const res = await getOrderInfoApi({ orderStatus: this.orderStatus });
         if (!res) return;
         this.orderDetail = res.data
      },
      async __receiveGoods(orderId) {
         const res = await receiveGoodsApi({ orderId: orderId });
         if (!res) return;
         this.$message({message: '确认收货成功', type: 'success'});
         this.__getOrderInfo()
      },
      async __addComment() {
         let data = {
            rating: this.commentForm.rating,
            content: this.commentForm.content,
            orderItems: this.orderItemsData
         }
         let res = await addCommentApi(data);
         if (!res) return;
         this.$message({message: '添加评论成功', type: 'success'});
         this.openComment = false
         this.__getOrderInfo()
         // console.log(orderItems);
      },
      openCommentForm(orderItems) {
         this.openComment = true;
         this.orderItemsData = orderItems;
      }
   }
}
</script>

<style lang="less" scoped>

.shop-hd {
   background-color: #fff;
   
   td {
      border: 1px #e5e5e5 solid;
   }
}
.footer {
   background-color: #fff!important;
}

.caozuo a {
	display: inline-block;
   cursor: pointer;
	text-decoration: none;
	color: #fff;
	background: #39bf3e;
	overflow: hidden;
	padding: 5px 4px;
	border-radius:3px;
	margin: 5px;
}

.shop {
	width: 350px;
	overflow:hidden;
	margin: 10px;

   img {
      float: left;
   }

   a {
      width:255px;
      text-decoration: none;
      color: #333;
      float: left;
      margin-left: 13px;
      margin-top: 5px;
   }
}

.title {
    color: #b4b4b4;
}

table {
   width: 978px;
   overflow: hidden;
   margin: 0px auto;
   margin-top: 20px;
   border: 1px #e5e5e5 solid;
   font-size: 12px;
   box-shadow: 0px 0px 10px #ccc;
}

thead {
   a {
      cursor: pointer;
   }

   td {
      padding: 11px 0px;
      padding-left: 20px;
   }

   tr {
      background: #f5f5f5;
   }

   tbody td {
      line-height: 20px;
   }

}

#zhu {
	margin-top:10px;
	width: 1020px;
	overflow:hidden;
	background: #fff;
	float: left;
	box-shadow: 0px 0px 5px #ccc;
	margin-bottom: 10px;
	padding-bottom:20px;

   p {
      font-family: "microsoft yahei";
      font-size: 16px;
      font-weight: bold;
      color: #666;
      padding: 7px 20px;
      border-bottom: 1px solid #efefef;
   }

   ul {
      margin:0px;
      padding:0px;
      list-style: none;
      width: 980px;
      margin: 0px auto;	
      margin-top: 20px;
      font-size: 14px;

      li {
         line-height: 50px;
      }
   }
}

[name=count] {
   color: #ff3366;
}

#body {
   width: 1200px;
   overflow: hidden;
   margin: 0px auto;
   margin-bottom: 70px;

   #left {
      width: 158px;
      overflow: hidden;
      float: left;
      margin-right: 10px;
      background: #fff;
      box-shadow: 0px 0px 5px #ccc;
      padding-top: 25px;
      padding-bottom: 25px;
      margin-top: 10px;
      margin-left: 5px;

      dl {
         font-size: 14px;
         margin-left: 25px;
         line-height: 23px;

         dt {
            font-weight: bold;
            margin-bottom: 5px;
            cursor: pointer;

            img {
               margin-right: 5px;
            }
         }

         dd {
            margin-left: 16px;
            // font-family: Simsun;
            font-size: 12px;

            a {
               text-decoration: none;
               color: black;
               cursor: pointer;
            }
         }
      }
   }
}

.kk {
   width: 180px;
   border-right: 1px #e5e5e5 solid;
   border-bottom:1px #e5e5e5 solid;
   vertical-align: middle;
}

.mm {
   width: 200px;
   border-bottom:1px #e5e5e5 solid;
   vertical-align: middle;
}

.order {
   width: 100%;
   height: 100%;
   background-color: #f5f5f5;

   .order-content {
      width: 100%;
      margin: 0 auto;
      
   }
}

html, body, #app {
   background-color: #f5f5f5!important;
}
</style>