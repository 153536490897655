<template>
   <div class="header-content">
      <div id="div_back">
         <div id="div_ul">
            <ul>
               <li>网站分类</li>
               <li>商家入驻</li>
               <li>客户服务</li>
            </ul>
            <ul class="daohang" style="float:right">
               <li v-if="!isLogin" class="login_DengLu" id="denglu"><router-link to="/login">您好，请登录</router-link></li>
               <li v-else>
                  <el-dropdown @command="hdCommand">
                     <span style="font-size: 12px;" class="el-dropdown-link">
                        {{ userInfo.userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
                     </span>
                     <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="home">个人中心</el-dropdown-item>
                        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
               </li>
               <li><router-link to="/order">我的订单</router-link></li>
               <li><router-link to="/cart"><img src="../assets/img/cart.png" alt="">购物车</router-link></li>
               <li>
                  <el-tooltip placement="top">
                     <div slot="content">
                        我们很高兴地宣布，本月我们推出了<br/>
                        一系列全新的有机农产品，包括有机<br/>
                        蔬菜、有机水果和有机谷物。这些产<br/>
                        品均来自绿色环保的有机农场，确保<br/>
                        天然无污染。欢迎选购尝鲜！<br/>
                     </div>
                     <i class="el-icon-message">公告</i>
                  </el-tooltip>
               </li>
               <li>企业采购</li>
            </ul>
         </div>

         <!-- 个人中心 -->
         <div id="ding" v-if="homeShow">
            <div id="ding_border">
               <router-link to="/"><img alt="" @click="goTo" src="../assets/img/wdhn.png" style="height: 100%;"></router-link>
               
               <!-- <span style="display: block; font-size: 40px; color: #fff; font-weight: 700;">我的惠农</span> -->
               <div id="daohang">
                  <ul>
                     <li><router-link to="/" style="color: #fff;">首页</router-link></li>
                     <li><router-link to="/home"  style="color: #fff;">账户管理</router-link></li>
                  </ul>
               </div>
            </div>
         </div>

         <!-- 搜索框 -->
         <div id="DaoHang" v-if="!homeShow">
            <div class="one">
               <router-link to="/"><img src="../assets/img/hng.png" alt="" style="width: 100%;"></router-link>
            </div>

            <!-- 搜索框 右侧 -->
            <form v-if="!show" id="search" style="float: right; transform: translateY(50%);">
               <!-- <img src="../assets/img/serach.png"> -->
               <i class="el-icon-search search-icon"></i>
               <input type="text" placeholder="请输入您要的商品" name="sousuo">
               <p>搜 索</p>
            </form>

            <!-- 搜索框 中间 -->
            <div class="two" v-if="show">
               <form id="search">
                  <!-- <img src="../assets/img/serach.png"> --> 
                  <i class="el-icon-search search-icon"></i>
                  <input type="text" placeholder="请输入您要的商品" v-model="keyword" name="sousuo">
                  <p @click="search">搜 索</p>
               </form>
               <ul>
                  <li class="style">水果</li>
                  <li class="shu" style="padding: 0px 10px;color: #ddd">|</li>
                  <li class="style">蔬菜</li>
                  <li class="shu" style="padding: 0px 10px;color: #ddd">|</li>
                  <li class="style">水产</li>
                  <li class="shu" style="padding: 0px 10px;color: #ddd">|</li>
                  <li class="style">禽畜</li>
                  <li class="shu" style="padding: 0px 10px;color: #ddd">|</li>
                  <li class="style">苗木花草</li>
               </ul>
            </div>
         </div>

         <!-- 导航栏 -->
         <div id="DaoHangTiao_back" v-if="navShow && !homeShow">
            <div id="DaoHangTiao">
               <ul id="ul">
                  <li class="xianshi" value="1"><a href="#">首页</a></li>
                  <li class="xianshi" value="1" v-for="(item, index) in navList" :key="index"><a @click="goToSearch(item.categoryId)">{{ item.title }}</a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
  
<script>
import { mapMutations, mapState } from "vuex"
import { getNavListApi } from "@/api/index.js"
import userInfo from "@/store/userInfo";

export default {
   props: {
      show: {
         type: Boolean,
         default: true
      },
      navShow: {
         type: Boolean,
         default: true
      },
      homeShow: {
         type: Boolean,
         default: false
      }
   },
   data() {
      return {
         navList: [],
         isLogin: false,
         keyword: "",
         content: "我们很高兴地宣布，本月我们推出了一系列全新的有机农产品，包括有机蔬菜、有机水果和有机谷物。这些产品均来自绿色环保的有机农场，确保天然无污染。欢迎选购尝鲜！"
      }
   },
   created() {
      this.__getNavList();
      this.isLogin = !!localStorage.getItem("token")
   },
   computed: {
      ...mapState({
         userInfo: state => state.userInfo
      })
   },
   methods: {
      hdCommand(command) {
         if (command === "logout") {
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            this.$router.push("/login")
         } else {
            this.$router.push("/home")
         }
      },
      goTo() {
         this.$router.push("/")
      },
      goToSearch(categoryId) {
         this.$router.push({ path: '/search', query: {categoryId: categoryId} });
      },
      search() {
         this.$router.push({ path: '/search', query: {keyword: this.keyword} });
      },
      async __getNavList() {
         const res = await getNavListApi();
         if (!res) return;
         this.navList = res.data;
      }
   }
}
</script>
  
<style lang="less" scoped>
.search-icon {
   position: absolute;
   margin-top: 14px;
   margin-left: 8px;
   color: #b6b5b5;
}

#ding {
   width: 100%;
   // height: 53px;
   background: #39bf3e;

   #ding_border {
      width: 1190px;
      height: 80px;
      margin: 0px auto;

      img {
         // margin-top: 12px;
         float: left;
         margin-right: 150px;
         margin-left: 10px;
      }
   }

   #daohang {
      // width: 910px;
      // height: 53px;
      float: left;
      margin-top: 16px;

      ul {
         list-style: none;
         margin: 0px;
         // padding_border: 0px;
         font-size: 16px;
         color: #fff;

         li {
            float: left;
            margin: 17px 17px;
         }
      }
   }
}

/* 总尺寸 宽1190 */
.header-content {
   width: 100%;
   overflow: hidden;
   margin: 0px auto;
   background-color: #fff;
}

.header-content #div_back {
   height: 36px;
   width: 100%;
   background-color: #F5F5F5;

   #div_ul {
      width: 1190px;
      height: 36px;
      line-height: 16px;
      margin: 0px auto;

      ul {
         margin: 0px;
         padding: 0px;
         list-style: none;
         font-size: 12px;
         cursor: pointer;

         li {
            float: left;
            padding: 8px 12px;
            color: #666666;
            border: 1px #F5F5F5 solid;
         }
      }
   }
}

.div_wzdh {
   margin: 0px;
   padding: 0px;
   position: absolute;
   top: 34px;
   width: 120px;
   /* height:0px; */
   height: 67px;
   overflow: hidden;
   margin-left: -13px;
   border: 1px #eeeeee solid;
   border-top: 0px;
   background-color: #ffffff;
   cursor: default;
   z-index: 9999;
   /* opacity:0; */
   display: none;

   a {
      margin: 0px;
      padding: 0px;
      text-decoration: none;
      display: inline-block;
      padding: 8px 15px;
      color: #666;
   }
}

.div_wzdh a:hover {
   color: #39bf3e;
}

.div_wzdh div {
   width: 190px;
   overflow: hidden;
   float: left;
   border-right: 1px #eeeeee solid;
   float: left;

   p {
      margin-bottom: 2px;
      font-size: 14px;
      color: #000000;
      padding-left: 20px;
   }
}

.div_wzdh div dl {
   margin: 0px;
   padding: 0px;
   list-style: none;
   font-size: 13px;
   padding-left: 20px;
   float: left;
   cursor: pointer;
}

.div_wzdh div dl dd {
   margin: 0px;
   padding: 0px;
   margin-top: 8px;
}

.div_wzdh div dl dd:hover {
   color: blue;
}

.header-content #div_back #div_ul ul .xia:hover {
   border: 1px #eeeeee solid;
   background-color: #ffffff;
}

.div_back {
   height: 100%;
}

// 搜索框
#DaoHang {
   width: 1190px;
   height: 110px;
   margin: 0px auto;
}

#DaoHang .one {
   width: 190px;
   height: 110px;
   float: left;
}

#DaoHang .one img {
   margin-top: 8px;
}

#search {
   img {
      position: absolute;
      margin-top: 10px;
      margin-left: 8px;
   }

   input {
      width: 413px;
      height: 40px;
      outline: none;
      border: 2px #39bf3e solid !important;
      float: left;
      text-indent: 2em;
      margin-bottom: 5px;
   }

   p {
      display: block;
      padding: 14.5px 40px;
      background-color: #39bf3e !important;
      float: left;
      color: #fff;
      cursor: pointer;
   }
}

#DaoHang .two {
   width: 540px;
   height: 69px;
   float: left;
   margin-top: 35px;
   margin-left: 165px;

}

#DaoHang .two ul {
   list-style: none;
   font-size: 12px;
   clear: both;
   color: #666666;

   li {
      float: left;
   }

   .shu {
      font-size: 9px;
      color: #ddd;
      padding: 0px 10px;
   }
}

// 导航条
#DaoHangTiao_back {
   width: 100%;
   height: 38px;
   background-color: #39bf3e;
   z-index: 1500;

   #DaoHangTiao {
      width: 1190px;
      height: 38px;
      margin: 1px auto;

      ul {
         list-style: none;
         font-size: 15px;
         font-family: '微软雅黑';
         height: 38px;
         clear: both;

         li {
            float: left;
            cursor: pointer;

            a {
               display: block;
               text-decoration: none;
               color: #ffffff;
               padding: 9px 30px;
               transition: all 0.2s ease-in-out;
            }
         }
      }
   }
}

#DaoHangTiao_back #DaoHangTiao ul li a:hover {
   background-color: #39bf3e;
   transition: all 0.2s ease-in-out;
}
</style>
